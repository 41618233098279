import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FaRedo } from 'react-icons/fa';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Button from '../../core/Button/Button';
import ReactFileReader from 'react-file-reader';
import csv from 'csv';
import { checkTagsHandler, addTagsHandler } from '../../../store/actions/ProjectsActions';

class AddTags extends Component {

    state = {
        selected: 'insert',
        tagsInput: '',
        tagsToAdd: [],
        notFoundTags: [],
        checkClicked: false,
        isChecking: false,
        checkPass: false,
        saveClicked: false,
        isLoading: false,
        saveSuccess: false,
        count: 0,
        errorMessage: '',
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selected !== this.state.selected) {
            this.setState({ 
                tagsToAdd: [],
                notFoundTags: [],
                checkClicked: false,
                checkPass: false,
                saveClicked: false,
                isLoading: false,
                saveSuccess: false,
            })
        }
    }

    render() {
        const { closePopup } = this.props;
        const { selected, tagsToAdd, count, tagsInput, checkPass, notFoundTags, checkClicked, isChecking, saveClicked, isLoading, saveSuccess, errorMessage } = this.state;
        let insertBox = "col-8 insert-container scrollY bg-light mr-3 p-1"
        let uploadBox = "col-3 upload-file-container bg-light p-0"
        if (isLoading) {
            return <div className="spinner-border text-primary p-4 mx-auto" role="status"></div>
        } else {
            return (
                <div className="popup">
                    {saveSuccess ?
                        <div className="title mx-4 d-flex justify-content-center align-items-center">
                            <img className="pointer arrow-back" src={require('../../../assets/images/backArrow.png')} alt="back-arrow"
                                onClick={() => {
                                    const tags = tagsToAdd.map(tag => tag.toUpperCase())
                                    this.setState({ saveSuccess: false })
                                    closePopup(tags)
                                }} />
                            <h1 className="text-center">
                                Successful saving tags
                            </h1>
                        </div>
                        :
                        <div style={{
                            // margin: checkPass || (isChecking && tagsToAdd.length > 0) || notFoundTags.length > 0 ? '120px 50px 0 50px' : 0
                        }}>
                            <div className="title mx-4 d-flex justify-content-center align-items-center">
                                <img className="pointer arrow-back" src={require('../../../assets/images/backArrow.png')} alt="back-arrow" onClick={() => closePopup()} />
                                <h1 className="text-center">Add Tag</h1>
                            </div>
                            <div>
                                <div className="d-flex mt-3 mb-2 justify-content-center">
                                    {checkPass ?
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div>
                                                <h3>The check was completed successfully</h3>
                                                <div className="mb-3">
                                                    <div>The tags you entered are: </div>
                                                    <div>
                                                        {`${tagsToAdd}`}
                                                    </div>
                                                </div>                                                
                                            </div>
                                        </div>
                                        :
                                        isChecking && tagsToAdd.length > 0 ?
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div className="spinner-border mb-3 text-primary" role="status"></div>
                                            </div>
                                            :
                                            notFoundTags.length > 0 ? (
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <div>
                                                        <h3>Failed to validate all tags</h3>

                                                        {notFoundTags.length < tagsToAdd.length && (
                                                            <div className="mb-1">
                                                                Valid tags: {' '}
                                                                <strong>{
                                                                    tagsToAdd.map(t => t.toUpperCase())
                                                                        .filter(t => !notFoundTags.includes(t))
                                                                        .join(', ')
                                                                }</strong>
                                                            </div>
                                                        )}

                                                        <div className="mb-3">
                                                            Invalid tags: {' '}
                                                            <strong>{notFoundTags.join(', ')}</strong>
                                                        </div>                                                       
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className={selected === 'insert' ? `${insertBox} selected tags-tooltip` : insertBox} onClick={() => this.setState({ selected: 'insert' })}>
                                                    {selected === 'insert' || selected === '' ?
                                                        <div>
                                                            <div className="tool-tip">
                                                                Use 'Tab' button in order to seperate between tags.                                                        
                                                            </div>
                                                            <div className="d-flex flex-wrap align-items-center">
                                                                {tagsToAdd ?
                                                                    tagsToAdd.map((tag, index) => (
                                                                        <div key={index} className="mr-1 mt-4" style={{ borderRadius: '20px' }}>
                                                                            <Chip
                                                                                size={'small'}
                                                                                label={`${tag}`}
                                                                                variant={'outlined'}
                                                                                color={'primary'}
                                                                                onDelete={() => this.removeTagHandler(tag)}
                                                                            />
                                                                        </div>
                                                                    ))
                                                                    : null}
                                                                <div className="ml-2">
                                                                    <TextField
                                                                        id="standard-basic"
                                                                        label="Tag"
                                                                        value={tagsInput}
                                                                        style={{ width: '100px' }}
                                                                        onChange={(event) => this.setState({ tagsInput: event.target.value, checkClicked: false, })}
                                                                        onKeyDown={(event) => this.insertTagHandler(event, 'keyDown')}
                                                                        onBlur={(event) => this.insertTagHandler(event, 'blur')}
                                                                        disabled={selected !== 'insert'}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='d-flex justify-content-center align-items-center' style={{ height: '100%' }} onClick={() => this.setState({ selected: 'upload' })} >
                                                            <FaRedo className="pointer" size={44} />
                                                            <div className="input-label center ml-3 pointer" >
                                                                Switch to manual insert
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                    }
                                    {checkPass || (isChecking && tagsToAdd.length > 0) || notFoundTags.length > 0 ?
                                        null
                                        :
                                        <div className={selected === 'upload' ? `${uploadBox} selected` : uploadBox} onClick={() => this.setState({ selected: 'upload' })}>
                                        <div className="upload-file d-flex justify-content-center align-items-center" onClick={() => this.setState({ selected: 'upload' })}>
                                            <div className="d-block pointer p-2">
                                                {selected === 'upload' || selected === '' ? <img src={require('../../../assets/images/uploadIcon.png')} alt="back-arrow" /> : null}
                                                {selected === 'insert' ? <FaRedo className="pointer" size={44} /> : null}
                                                <div className="input-label text-center mt-2" >
                                                    {selected === 'insert' ? `Switch to upload file` : `Upload a file with a list of tags`}
                                                </div>
                                                {selected === 'upload' ?
                                                    <ReactFileReader handleFiles={this.handleUpload} fileTypes={'.csv'}>
                                                        <button
                                                            className={selected === 'upload' ? 'file-input pointer' : 'file-input'}
                                                            disabled={selected !== 'upload'}
                                                        >
                                                            Upload
                                                         
                                                        </button>
                                                    </ReactFileReader> : null}
                                            </div>
                                            {selected === 'upload' ?  
                                            <div className="tool-tip">The system accepts a list of tags as a .CSV file - a plain text format where tags can be separated by commas or on new lines.
                                                <br></br>  For example:
                                                <div className="font-ex">
                                                        <br></br>
                                                        tag_a, tag_b
                                                        <br></br>
                                                        tag_c, tag_d, tag_e
                                                        <br></br>
                                                        tag_f
                                                        <br></br>
                                                        tag_g
                                                        <br></br>
                                                        tag_h,tag_i,tag_j,tag_k
                                                </div> 
                                            </div> : ''}
                                        </div>
                                    </div>
                                    }
                                </div>
                                {(saveClicked || checkClicked) && tagsToAdd.length === 0 && tagsInput.length === 0 ?
                                    <div className="text-center mb-2" style={{ color: '#dc3545' }}>
                                        {selected === 'insert' ? 'You must insert tags' : selected === 'upload' ?  'You must upload a csv file' : null }
                                    </div>
                                : null}
                            </div>
                            <div className="px-5 d-flex justify-content-center">
                                <div className="mx-2 d-flex justify-content-center">
                                    <div className="mr-2">
                                    {notFoundTags.length > 0 ?
                                            <Button
                                                title={"Try again"}
                                                clickHandler={() => {
                                                    this.setState({ notFoundTags: [], checkClicked: false, count: 0 })
                                                }}
                                            />
                                        :
                                        checkPass ?
                                            <Button
                                                title={"SAVE"}
                                                clickHandler={() => {
                                                    this.setState({ saveClicked: true })
                                                    this.handleSave()
                                                }}
                                            />
                                        : !checkPass ?
                                            <Button
                                            title={"CHECK"}
                                            clickHandler={() => {
                                                this.setState({ isChecking: true, checkClicked: true, count: count + 1 })
                                                this.handleTagsCheck()
                                            }}
                                        >
                                        </Button>
                                        : null }
                                    </div>
                                    <div>
                                        <Button outlineBlue title={"CANCEL"} clickHandler={() => closePopup()}></Button>
                                    </div>
                                </div>
                            </div>                                
                        </div>
                    }
                </div>
            );
        }
    }

    handleTagsCheck = async () => {
        const { checkTagsHandler, dsName, tableName } = this.props;
        const { tagsToAdd } = this.state;
        // console.log("tagsToAdd: ", tagsToAdd)
        const response = await checkTagsHandler(dsName, tableName, tagsToAdd);
        if (response.data.tag_results.length > 0) {
            const results = response.data.tag_results[0].tags
            // console.log(results)
            const tags = Object.keys(results);
            const notFound = [];
            tags.forEach(tag => {
                if (results[tag] === 'not_found') {
                    notFound.push(tag)
                }
            })
            this.setState({
                notFoundTags: notFound,
                isChecking: false,
                checkPass: notFound.length === 0 && tagsToAdd.length > 0 ? true : false
            }, () => console.log())
        }
    }

    handleSave = async () => {
        const { addTagsHandler, projectName, dsName, tableName } = this.props;
        const { tagsToAdd } = this.state;
        this.setState({ isLoading: true })
        try {
            const response = await addTagsHandler(projectName, dsName, tableName, tagsToAdd)
            this.setState({
                isLoading: response ? false : true,
                saveSuccess: response.data.Status === "Success"
            })
        } catch(err) {
            this.setState({
                isLoading: false,
                saveSuccess: false,
                errorMessage: err.response.data
            })
        }
    }

    handleUpload = files => {
        var reader = new FileReader();
        const self = this;
        const toAdd = []
        reader.onload = function (e) {
            const str = reader.result.split('\n').join('');
            // console.log(str)
            csv.parse(str, {}, (err, data) => {
                data.forEach(el => {
                    el.forEach((word, index) => {                        
                        toAdd.push(word.trim())
                    })
                })
                // console.log(toAdd)
                self.setState({ tagsToAdd: toAdd })
            });
        }
        reader.readAsText(files[0]);
    }

    insertTagHandler = (event, type) => {
        const newTag = event.target.value;
        if (newTag !== '' && newTag !== ' ') {
            if (type === 'keyDown') {
                if (event.which === 13 || event.which === 9) {
                    this.setState(prevState => {
                        return {
                            tagsToAdd: [...prevState.tagsToAdd, newTag],
                            tagsInput: '',
                        }
                    })
                }
            } else if (type === 'blur') {
                this.setState(prevState => {
                    return {
                        tagsToAdd: [...prevState.tagsToAdd, newTag],
                        tagsInput: '',
                    }
                })
            }
        }
    }

    removeTagHandler = (tag) => {
        const updatedTags = this.state.tagsToAdd.filter(t => t !== tag)
        this.setState({ tagsToAdd: updatedTags })
    }
}

const mapDispatchToProps = dispatch => {
    return {
        checkTagsHandler: (dsName, tableName, tagsToAdd) => dispatch(checkTagsHandler(dsName, tableName, tagsToAdd)),
        addTagsHandler: (projectName, dsName, tableName, tagsToAdd) => dispatch(addTagsHandler(projectName, dsName, tableName, tagsToAdd)),
    }
}

export default connect(null, mapDispatchToProps)(AddTags);
