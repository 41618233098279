import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from '../../core/Modal/Modal';
import TableNav from '../../core/TableNav/TableNav';
import Pagination from '../../core/Pagination/Pagination';
import DebugTagsDlg from '../../popups/DebugTags/DebugTagsDlg';


function DataSourceTags(props) {
	const match = useRouteMatch();
	const history = useHistory();
	const {path, url, params} = match;
	const {projectName, dsName, dsType} = params;
	const { dataSourcesFull, getDataSources, accountInfo } = props;

	const [action, setAction] = useState();
	const [isSelecting, setIsSelecting] = useState(false);
	const [selectedTags, setSelectedTags] = useState([]);
	const [searchValue, setSearchValue] = useState('');

	const [isDebugging, setIsDebugging] = useState(false);

	const tagTypes = dataSourcesFull?.find(el => el && el.historian_type === dsName)?.tag_types;
	const tags = tagTypes?.find(tagType => tagType.type === dsType)?.tags;

	const filteredTags = searchValue.length > 0 
		? tags?.filter(tag => tag.toLowerCase().includes(searchValue.toLowerCase())) 
		: tags;

	const selectTag = (tag) => {
		if (selectedTags.some(t => t === tag)) {
			let toDelete = [...selectedTags].filter(t => t !== tag);
			setSelectedTags(toDelete);
		} else {
			setSelectedTags([...selectedTags, tag]);
		}
	}

	useEffect(() => {
		if (!dataSourcesFull) {
			run();
		}

		async function run() {
			
			try {
				await getDataSources(projectName);
			}
			catch (err) {
				console.log('[DataSourceTags] getDataSources error:', err);
				history.replace('/projects', {error: err.response?.status === 403 ? `User ${accountInfo.email} is not authorized to access project ${projectName}` : (err.message ?? 'Server error')});
			}
		}
	}, []);


	if (!filteredTags) {
		return <div className="spinner-border text-primary p-4 mx-auto" role="status"></div>
	} else {
		return (
			<div className="tableContainer">
				<TableNav
					dsFlag
					primary={projectName}
					secondary={dsName}
					goBack={() => history.push(`/projects/${projectName}`)}
					cancel={() => {setAction(''); setIsSelecting(false); setSelectedTags([]);}}
					setShowPopup={() => history.push(`${url}/tags/add`)}
					isClicked={isSelecting}
					setIsClicked={(_action) => () => {setAction(_action); setIsSelecting(true); setSelectedTags([]);}}
					searchHandler={(value) => setSearchValue(value)}
					doAction={() => { 
						if (action === 'debug') { 
							setIsDebugging(true);
						} 
					}}
				/>
				<div className="col p-0">
					<div className="lightGrey">
						<div>
							<div className="tableRow">
								<div className="text-left p-3 pointer tableText">
									{dsType}
								</div>
							</div>
							<div className="innerTableContainer">
								{filteredTags.length === 0 ?
									<h4 className="m-0 p-5 text-center">There are no tags in this type yet</h4>
									:
									<div>
										<div className="text-left mb-3 tableText">Tag List</div>
										<Pagination
											list={filteredTags}
											isSelected={true}
											deleteClicked={isSelecting}
											selectedToDelete={selectedTags}
											addToDeleteList={selectTag}
										/>
									</div>
								}
							</div>
						</div>
					</div>
				</div>
				{(action === 'delete') ? (
					<Modal
						title={'Are you sure?'}
						projectName={projectName}
						dsName={dsName}
						tableName={dsType}
						tags={selectedTags}
						update={() => getDataSources(projectName)}
					/>
				) : (
					<DebugTagsDlg 
						isOpen={isDebugging}
						onClose={() => setIsDebugging(false)}
						dsName={dsName} dsType={dsType}
						tags={selectedTags}
					/>
				)}
			</div>
		)
	};
}


const mapStateToProps = state => {
	return {
			dataSourcesFull: state.projects.dataSourcesFull,
	}
}


export default connect(mapStateToProps, null)(DataSourceTags);
