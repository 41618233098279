import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '../../core/Button/Button';
import { addProjectHandler } from '../../../store/actions/AdminActions';
import ShowDetails from '../ShowDetails/ShowDetails';

class AddGroup extends Component {

    state = {
        projectName: '',
        saveClicked: false,
        isSaving: false,
        success: false,
        failed: false,
        details: null,
        errorMessage: '',
    }

    render() {
        const { failed, saveClicked, projectName, isSaving, success, errorMessage } = this.state;
        const { closePopup } = this.props;
        const invalid = "p-2 form-control is-invalid";
        const valid = "p-2 form-control is-valid"
        if (isSaving) {
            return <div className="spinner-border text-primary p-3 mx-auto" role="status"></div>
        } else if (failed) {
            return (
                <div className="popup">
                    <div className="title mx-4 d-flex justify-content-center align-items-center">
                        <img className="pointer arrow-back" src={require('../../../assets/images/backArrow.png')} alt="back-arrow" onClick={() => closePopup()} />
                        <h1 className="text-center">
                            {`Something went wrong - ${errorMessage}.`}
                        </h1>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="popup">
                    {success ?
                        <ShowDetails
                            // projectName={projectName}
                            details={this.state.details}
                            closePopup={() => closePopup()}
                        />
                        :
                        <div className="content-team">
                            <div className="title mx-4 mb-4 d-flex justify-content-center align-items-center">
                                <img className="pointer arrow-back" src={require('../../../assets/images/backArrow.png')} alt="back-arrow" onClick={() => closePopup()} />
                                <h1 className="text-center">Add project</h1>
                            </div>
                            <div className="container">
                                <div className="input-label"><span style={{ color: '#dc3545', fontWeight: 'bold' }}>* </span>Project name</div>
                                <div className="d-flex">
                                    <div style={{ width: '100%', textAlign: 'left' }}>
                                        <input className={saveClicked && projectName.length > 0 ? valid : saveClicked && projectName.length === 0 ? invalid : "p-2 form-control"} required onChange={(event) => this.setState({ projectName: event.target.value })} ></input>
                                        {saveClicked && projectName === '' ? <div className="invalid-feedback">Please provide a project name.</div> : null}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 d-flex justify-content-center">
                                <div className="mr-2">
                                    <Button title={"SAVE"} clickHandler={this.saveHandler}></Button>
                                </div>
                                <div>
                                    <Button title={"CANCEL"} outlineBlue clickHandler={() => closePopup()}></Button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            )
        }
    }



    saveHandler = async () => {
        const { projectName } = this.state;
        const { addProjectHandler } = this.props;
        this.setState({ saveClicked: true })
        let details;
        if (projectName !== '') {
            this.setState({ isSaving: true })
            try {
                const response = await addProjectHandler(projectName)
                details = response.data;
                this.setState({ isSaving: false, success: true, details: details }, () => console.log())
            }
            catch (err) {
                this.setState({ failed: true, isSaving: false, errorMessage: err.response.data })
            }
        }

    }
}

const mapDispatchToProps = dispatch => {
    return {
        addProjectHandler: (projectName) => dispatch(addProjectHandler(projectName)),
    }
}

export default connect(null, mapDispatchToProps)(AddGroup);
