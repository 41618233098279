import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import Button from '../Button/Button';

class TableRow extends Component {
    state = {
        isSelected: false,
        list: null,
        deleteClicked: false
    }

    render() {
        const { list, isSelected } = this.state;
        const { item, clickHandler, dsFlag } = this.props;
        return (
            <div>
                <div className="tableRow text-left pointer tableText justify-content-between" style={{ padding: dsFlag ? '16px' : '8px' }} onClick={() => this.handleRowClick()}>
                    {item}
                    {this.renderElement()}
                </div>

                {isSelected && dsFlag ?
                    <div className="innerTableContainer">
                        {!list ?
                            <div className="spinner-border text-primary p-3" role="status"></div>
                            :
                            list.length === 0 ?
                                <tbody className="lightGrey">
                                    <h4 className="m-0 p-5">There are no tags in this data source yet</h4>
                                </tbody>
                                :
                                list.map((item, index) => {
                                    return (
                                        <div key={index} className="innerTableName pointer tableText font-weight-bold col-4" onClick={() => clickHandler(item)}>
                                            {item.type}
                                        </div>
                                    )
                                })}
                    </div>
                    : null}
            </div>
        )
    }

    renderElement = () => {
        const { isSelected } = this.state;
        const { item, dsFlag, groupFlag, setGroup } = this.props;
        if (dsFlag) {
            if (isSelected) {
                return <FaAngleUp className="pointer" size={16} />
            } else {
                return <FaAngleDown className="pointer" size={16} />
            }
        } else if (groupFlag) {
            return (
                <div className="mr-3">
                    <Button
                        clickHandler={(e) => {
                            e.stopPropagation()
                            setGroup(item)
                        }}
                        title={'DELETE'}
                        dataToggle="modal"
                        dataTarget="#exampleModal"
                    />
                </div>
            )
        }
    }

    handleRowClick = () => {
        const { groupFlag, dsFlag, clickHandler, item } = this.props;
        const toggle = !this.state.isSelected
        this.setState({ isSelected: toggle })
        if (dsFlag) {
            this.filterDs(item)
        } else if (groupFlag) {
            clickHandler()
        }
    }

    filterDs = (dsName) => {
        const { dataSourcesFull } = this.props;
        const filtered = dataSourcesFull.filter(el => el && el.historian_type === dsName).map(el => el.tag_types)
        if (filtered.length > 0) {
            this.setState({ list: filtered[0] })
        }
    }
}

const mapStateToProps = state => {
    return {
        dataSourcesFull: state.projects.dataSourcesFull,
    }
}

export default connect(mapStateToProps, null)(TableRow);
