import React, { useEffect, useState } from 'react';
import Dialog, { DialogButton } from '../../core/Dialog/Dialog';
import { Project } from '../../../models/Project';
import { User } from '../../../models/User';

type EditProjectDlgProps = {
    isOpen: boolean;
    project?: Project;
    users?: User[];
    updateProject: (project: Project) => void;
    onClose: () => void;
};


export default function EditProjectDlg(props: EditProjectDlgProps) {
    const {isOpen, project, users, updateProject, onClose} = props;

    const [editedProject, setEditedProject] = useState<Project>({name: ''});

    useEffect(() => {
        if (!isOpen || !project) { return; }
        setEditedProject({...project});
    }, [project, isOpen]);

    const onChange = (field: string) => (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => {
        setEditedProject({...editedProject, [field]: e.target.value});
    };

    const onChangeContact = (idx: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        if (editedProject.contact_emails === undefined) { return; }
        const contacts = editedProject.contact_emails.split(',');
        contacts[idx] = e.target.value;
        setEditedProject({...editedProject, contact_emails: contacts.join(',')});
    };

    const addContact = () => {
        const contacts = editedProject.contact_emails?.split(',') ?? [];
        if (contacts.length >= 10) { return; }
        if (contacts.some(contact => contact.trim().length === 0)) { return; }

        contacts.push('');
        setEditedProject({...editedProject, contact_emails: contacts.join(',')})
    };

    const removeContact = (idx: number) => {
        if (editedProject.contact_emails === undefined) { return; }
        const contacts = editedProject.contact_emails.split(',');
        contacts.splice(idx, 1);
        setEditedProject({...editedProject, contact_emails: contacts.join(',') || undefined});
    };

    const buttons: DialogButton[] = [
        {title: "Cancel", onClick: onClose}, 
        {title: "Update", primary: true, onClick: () => updateProject(editedProject)}
    ];

    const contacts = editedProject.contact_emails?.split(',') ?? [];

    return (
        <Dialog
            isOpen={isOpen}
            title="Edit Project"
            size="lg"
            maxHeight={550}
            buttons={buttons}
            onCancel={onClose}
        >
            <div className="form-group">
                <label>Project: {editedProject.name}</label>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label">Type:</label>
                <div className="col-sm-10">
                    <select className="form-control form-control-sm" value={editedProject.type} onChange={onChange('type')}>
                        <option value=""></option>
                        <option value="cds">CDS</option>
                        <option value="finance">Finance</option>
                        <option value="vendor">Vendor</option>
                    </select>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label">Status:</label>
                <div className="col-sm-10">
                    <select className="form-control form-control-sm" value={editedProject.status} onChange={onChange('status')}>
                        <option value=""></option>
                        <option value="qa">QA</option>
                        <option value="in-progress">In Progress</option>
                        <option value="done">Done</option>
                        <option value="staging">Staging</option>
                        <option value="on-hold">On Hold</option>
                    </select>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label">Status Comment:</label>
                <div className="col-sm-10">
                    <textarea className="form-control form-control-sm" rows={2} maxLength={500} value={editedProject.status_comment ?? ''} onChange={onChange('status_comment')} />
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label">Manager:</label>
                <div className="col-sm-10">
                    <select className="form-control form-control-sm" value={editedProject.manager_email ?? ''} onChange={onChange('manager_email')} disabled={!users}>
                        {users ? (<>
                            <option value=""></option>
                            {users.map(({email, name}) => (
                                <option key={email} value={email}>{email}{name ? ` (${name})` : ``}</option>
                            ))}                    
                        </>) : (
                            <option value={editedProject.manager_email}>{editedProject.manager_email ?? ''}</option>
                        )}
                    </select>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label">Vendor:</label>
                <div className="col-sm-10">
                    <input type="text" className="form-control form-control-sm" value={editedProject.vendor_name ?? ''} onChange={onChange('vendor_name')} />
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label">Description:</label>
                <div className="col-sm-10">
                    <textarea className="form-control form-control-sm" rows={2} value={editedProject.description ?? ''} onChange={onChange('description')} />
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label">Contacts:</label>
                <div className="col-sm-10">
                    {contacts.map((contact, idx) => (
                        <div key={idx} className="d-flex mb-2">
                            <input autoFocus type="email" className="form-control form-control-sm" value={contact} onChange={onChangeContact(idx)} placeholder="Contact email" />
                            <button type="button" className="close ml-2" onClick={e => removeContact(idx)}><span>&times;</span></button>
                        </div>
                    ))}
                    {contacts.length < 10 && (
                        <div>
                            <button type="button" className="btn btn-sm btn-primary" disabled={contacts.some(c => !c)} onClick={() => addContact()}>Add contact</button>
                        </div>
                    )}
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label">Jira Tickets:</label>
                <div className="col-sm-10">
                    <textarea className="form-control form-control-sm" rows={2} maxLength={500} value={editedProject.jira_tickets ?? ''} onChange={onChange('jira_tickets')} />
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label">Data Paths:</label>
                <div className="col-sm-10">
                    <textarea className="form-control form-control-sm" rows={2} maxLength={1000} value={editedProject.data_paths ?? ''} onChange={onChange('data_paths')} />
                </div>
            </div>
        </Dialog>
    );
}


