import axios from 'axios';
import { API } from '../../config';
import groupsDummyData from '../../groupsDummyData.json';

export const SET_PROJECTS = 'SET_PROJECTS';
export const SET_SELECTED_PROJECT_DETAILS = 'SET_SELECTED_PROJECT_DETAILS';
export const SET_DS = 'SET_DS';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_GROUPS = 'SET_GROUPS';
export const SET_GROUP_EMAILS = 'SET_GROUP_EMAILS';
export const SEARCH_EMAILS = 'SEARCH_EMAILS';
export const SET_DASHBOARDS = 'SET_DASHBOARDS';
export const CLEAR_DATA = 'CLEAR_DATA';


export const getProjectList = (user) => {
    return async dispatch => {
        const response = await axios.get(`${API}/getProjectsByUserId?userId=${user}`)
        dispatch({ type: SET_PROJECTS, projects: response.data["data"] })
    }
}

export const getProjectDetails = (projectName) => {
    return async dispatch => {
        const response = await axios.get(`${API}/projects?project=${projectName}`)
        dispatch({ type: SET_SELECTED_PROJECT_DETAILS, details: response.data[projectName] })
        return response;
    }
}

export const getDataSources = (projectName) => {
    return async dispatch => {
        const response = await axios.get(`${API}/DataSources?project=${projectName}`)
        dispatch({ type: SET_DS, dataSources: response.data["data"] })
    }
}

export const getPermissions = (projectName) => {
    return async dispatch => {
        const response = await axios.get(`${API}/permissions?project=${projectName}`)
        dispatch({ type: SET_PERMISSIONS, permissions: response.data[projectName] })
    }
}

export const deleteTagsHandler = (projectName, dsName, tableName, tagsToDelete) => {
    return async dispatch => {
        return axios.post(`${API}/permissions/remove`, {
            project: projectName,
            historian_type: dsName,
            tag_types: [
                {
                    type: tableName,
                    tags: tagsToDelete
                }
            ]
        })
    }
}

export const checkTagsHandler = (dsName, tableName, tagsToCheck) => {
    return async dispatch => {
        return axios.post(`${API}/tags/check`, {
            historian_type: dsName,
            tag_types: [
                {
                    type: tableName,
                    tags: tagsToCheck
                }
            ]
        })
    }
}

export const addTagsHandler = (projectName, dsName, tableName, tagsToAdd) => {
    return async dispatch => {
        return axios.post(`${API}/permissions/add`, {
            project: projectName,
            historian_type: dsName,
            tag_types: [
                {
                    type: tableName,
                    tags: tagsToAdd
                }
            ]
        })

    }
}

export const getGroupList = (projectName) => {
    return async dispatch => {
        const response = await axios.get(`${API}/monitor/list_topics?project=${projectName}`)
        dispatch({ type: SET_GROUPS, groups: response.data })
    }
}

export const getGroupEmails = (projectName, groupName) => {
    return async dispatch => {
        const realGroupName = getRealGroupName(projectName, groupName);
        const response = await axios.get(`${API}/monitor/list_subscriptions?project=${projectName}&topic=${realGroupName}`)
        dispatch({ type: SET_GROUP_EMAILS, emails: response.data })
        return response.data
    }
}

export const addGroupHandler = (projectName, groupName) => {
    return async dispatch => {
        return axios.post(`${API}/monitor/create_topic`, {
            project: projectName,
            topic: groupName
        })
    }
}

export const deleteGroupHandler = (projectName, groupName) => {
    return async dispatch => {
        const realGroupName = getRealGroupName(projectName, groupName);
        return axios.post(`${API}/monitor/delete_topic`, {
            project: projectName,
            topic: realGroupName
        })
    }
} 

export const subscribeHandler = (projectName, groupName, emailList) => {
    return async dispatch => {
        let promises = emailList.map(async email => {
            return await axios.post(`${API}/monitor/subscribe`, {
                project: projectName,
                topic: groupName,
                email: email
            })
        })
        return await Promise.all(promises);
    }
}

export const unsubscribeHandler = (projectName, groupName, emailList) => {
    return async dispatch => {
        const realGroupName = getRealGroupName(projectName, groupName);
        let promises = emailList.map(async email => {
            return await axios.post(`${API}/monitor/unsubscribe`, {
                project: projectName,
                topic: realGroupName,
                email: email
            })
        })
        return await Promise.all(promises);
    }
}

export const getDashboardList = (projectName) => {
    return async dispatch => {
        const response = await axios.get(`${API}/analytics/dashboards?project=${projectName}`)
        dispatch({ type: SET_DASHBOARDS, dashboards: response.data })
    }
}

export const clearData = alsoProjects => {
    return { type: CLEAR_DATA, alsoProjects }
}

const getRealGroupName = (projectName, groupName) => {
    let index = "DIG-DATALAKE-".length + projectName.length + 1;
    let realGroupName = groupName.substring(index);
    return realGroupName
}
