import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Button from '../../core/Button/Button';
import { addGroupHandler, subscribeHandler, getGroupList } from '../../../store/actions/ProjectsActions';
import { withRouter } from 'react-router-dom';


class AddGroup extends Component {

    state = {
        groupName: '',
        emailsInput: '',
        isValid: true,
        emailsToAdd: [],
        saveClicked: false,
        isSaving: false,
        saveSuccess: false,
        saveFailed: false,
        errorMessage: '',
    }

    async componentDidUpdate(prevProps, prevState) {
        const { getGroupList, projectName, accountInfo } = this.props;
        if (prevState.saveSuccess !== this.state.saveSuccess) {
            try {
                await getGroupList(projectName);
            }
			catch (err) {
				console.log('[AddGroup] getGroupList error:', err);
				this.props.history.replace('/projects', {error: `User ${accountInfo.email} is not authorized to access project ${projectName}`});
			}
        }
    }

    render() {
        const { saveClicked, isSaving, groupName, emailsToAdd, emailsInput, isValid, saveSuccess, saveFailed, errorMessage } = this.state;
        const { closePopup, projectName, editGroupFlag } = this.props;
        const invalid = "p-2 form-control is-invalid";
        const valid = "p-2 form-control is-valid"
        if (isSaving) {
            return <div className="spinner-border text-primary p-4 mx-auto" role="status"></div>
        } else if (saveFailed) {
            return (
                <div className="popup">
                    <div className="title mx-4 d-flex justify-content-center align-items-center">
                        <img className="pointer arrow-back" src={require('../../../assets/images/backArrow.png')} alt="back-arrow" onClick={() => closePopup(emailsToAdd)} />
                        <h1 className="text-center">
                            {`Something went wrong - ${errorMessage}.`}
                        </h1>
                    </div>
                </div>
            )
        } else if (saveSuccess) {
            return (
                <div className="popup">
                    <div className="title mx-4 d-flex justify-content-center align-items-center">
                        <img className="pointer arrow-back" src={require('../../../assets/images/backArrow.png')} alt="back-arrow" onClick={() => closePopup(emailsToAdd)} />
                        <h1 className="text-center">
                            {editGroupFlag ? 'The subscriptions have been saved successfully' : 'The topic has been saved successfully'}
                        </h1>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="popup" style={{ width: '700px' }}>
                    <div className="content-team">
                        <div className="title mx-4 mb-5 d-flex justify-content-center align-items-center">
                            <img className="pointer arrow-back" src={require('../../../assets/images/backArrow.png')} alt="back-arrow" onClick={() => closePopup()} />
                            <h1 className="text-center">{editGroupFlag ? 'Add emails' : 'Add group'}</h1>
                        </div>
                        <div className="container">
                            {!editGroupFlag ?
                                <div>
                                    <div className="input-label"><span style={{ color: '#dc3545', fontWeight: 'bold' }}>* </span>Group name</div>
                                    <div className="d-flex center">
                                        <div className="prefix col-3 mr-2 p-0">
                                            <div>{`DIG-DATALAKE-${projectName}-`}</div>
                                        </div>
                                        <div style={{ width: '100%', textAlign: 'left' }}>
                                            <input className={saveClicked && groupName.length > 0 ? valid : saveClicked && groupName.length === 0 ? invalid : "p-2 form-control"} required onChange={(event) => this.setState({ groupName: event.target.value })} ></input>
                                            {saveClicked && groupName.length === 0 ? <div className="invalid-feedback">Please provide a group name.</div> : null}
                                        </div>
                                    </div>
                                </div> : null}
                            <div className="input-label mt-2">Email addresses</div>
                            <div className="insert-container scrollY bg-light p-1" style={{ height: '200px', width: '600px' }}>
                                <div className="d-flex flex-wrap align-items-center">
                                    {emailsToAdd ?
                                        emailsToAdd.map((email, index) => (
                                            <div key={index} className="mr-1 mt-4" style={{ borderRadius: '20px' }}>
                                                <Chip
                                                    size={'small'}
                                                    label={`${email}`}
                                                    variant={'outlined'}
                                                    color={'primary'}
                                                    onDelete={() => this.removeEmailHandler(email)}
                                                />
                                            </div>
                                        ))
                                        : null}
                                    <div className="ml-2 d-flex flex-column">
                                        <TextField
                                            id="standard-basic"
                                            label="Email"
                                            value={emailsInput}
                                            style={{ width: '220px' }}
                                            onChange={(event) => this.setState({ emailsInput: event.target.value, isValid: true })}
                                            onKeyDown={(event) => this.insertEmailHandler(event, 'keyDown')}
                                            onBlur={(event) => this.insertEmailHandler(event, 'blur')}
                                        />
                                        {isValid || emailsInput === '' ? null : <div className="text-left" style={{ color: '#dc3545', fontSize: '80%' }}>Please provide a valid email</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {saveClicked && editGroupFlag && emailsToAdd.length === 0 ? <div style={{ color: '#dc3545', fontSize: '80%' }}>* Please enter email addresses</div> : null}
                        <div className="mt-4 d-flex justify-content-center">
                            <div className="mr-2">
                                <Button title={"SAVE"} clickHandler={this.saveHandler}></Button>
                            </div>
                            <div>
                                <Button title={"CANCEL"} outlineBlue clickHandler={() => closePopup()}></Button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
    closePopupAddGroup() {
        this.setState({ saveSuccess: false })
        this.props.closePopup();
    }

    saveHandler = async () => {
        const { groupName, emailsToAdd, emailsInput } = this.state;
        const { projectName, addGroupHandler, subscribeHandler, editGroupFlag, groupToEdit } = this.props;
        this.setState({ saveClicked: true })
        if (this.validateEmail(emailsInput) || emailsInput.length === 0) {
            if (!editGroupFlag) {
                if (groupName.length > 0) {
                    this.setState({ isSaving: true })
                    if (emailsToAdd.length === 0) {
                        try {
                            const addGroupRes = await addGroupHandler(projectName, groupName)
                            if (addGroupRes && addGroupRes.status === 200 && addGroupRes.data) {
                                this.setState({ saveSuccess: true, saveFailed: false, isSaving: false })
                            } else {
                                this.setState({ saveSuccess: false, saveFailed: true, isSaving: false })
                            }
                        } catch (err) {
                            this.setState({ saveSuccess: false, saveFailed: true, isSaving: false, errorMessage: err.response.data })
                        }
                    } else if (emailsToAdd.length > 0) {
                        try {
                            const addGroupRes = await addGroupHandler(projectName, groupName);
                            const subsbcribeRes = await subscribeHandler(projectName, groupName, emailsToAdd)
                            if (addGroupRes && addGroupRes.data && addGroupRes.data.topic_name && subsbcribeRes && subsbcribeRes.length > 0) {
                                if (subsbcribeRes[0].data && subsbcribeRes[0].data.Status == "Success") {
                                    this.setState({ saveSuccess: true, saveFailed: false, isSaving: false })
                                } else {
                                    this.setState({ saveSuccess: false, saveFailed: true, isSaving: false })
                                }
                            } else {
                                this.setState({ saveSuccess: false, saveFailed: true, isSaving: false })
                            }
                        } catch (err) {
                            this.setState({ saveSuccess: false, saveFailed: true, isSaving: false, errorMessage: err.response.data })
                        }
                    }
                }
            }
            else {
                if (emailsToAdd.length > 0) {
                    this.setState({ isSaving: true })
                    try {
                        const subsbcribeRes = await subscribeHandler(projectName, groupToEdit, emailsToAdd)
                        if (subsbcribeRes && subsbcribeRes.length > 0) {
                            if (subsbcribeRes[0].data && subsbcribeRes[0].data.Status == "Success") {
                                this.setState({ saveSuccess: true, saveFailed: false, isSaving: false })
                            } else {
                                this.setState({ saveSuccess: false, saveFailed: true, isSaving: false })
                            }
                        }
                    } catch (err) {
                        this.setState({ saveSuccess: false, saveFailed: true, isSaving: false, errorMessage: err.response.data })
                    }
                }
            }
        }
    }

    insertEmailHandler = (event, type) => {
        const newEmail = event.target.value;
        if (!this.validateEmail(newEmail)) {
            this.setState({ isValid: false })
        } else {
            if (type === 'keyDown') {
                if (event.which === 13 || event.which === 9) {
                    this.setState(prevState => {
                        return {
                            emailsToAdd: [...prevState.emailsToAdd, newEmail],
                            emailsInput: '',
                            isValid: true
                        }
                    })
                }
            } else if (type === 'blur') {
                this.setState(prevState => {
                    return {
                        emailsToAdd: [...prevState.emailsToAdd, newEmail],
                        emailsInput: '',
                        isValid: true
                    }
                })
            }
        }
    }

    validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    removeEmailHandler = (email) => {
        const updatedEmails = this.state.emailsToAdd.filter(e => e !== email)
        this.setState({ emailsToAdd: updatedEmails })
    }
}


const mapDispatchToProps = dispatch => {
    return {
        addGroupHandler: (projectName, groupName) => dispatch(addGroupHandler(projectName, groupName)),
        subscribeHandler: (projectName, groupName, emailList) => dispatch(subscribeHandler(projectName, groupName, emailList)),
        getGroupList: (projectName) => dispatch(getGroupList(projectName)),
    }
}

export default connect(null, mapDispatchToProps)( withRouter(AddGroup) );
