import React, { Fragment, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import Dialog, { DialogButton } from '../../core/Dialog/Dialog';
import { API } from '../../../config';

const debugTagsMaxDays = 7;

type DebugItem = {
	[tag: string]: string;
	TS: string;
};

type DebugTagsDlgProps = {
    isOpen: boolean;
    tags: string[];
    dsName: string;
    dsType: string;
    onClose: () => void;
};

export default function DebugTagsDlg(props: DebugTagsDlgProps) {
    const {isOpen, onClose, tags, dsName, dsType} = props;

	const [isSending, setIsSending] = useState(false);
	const [results, setResults] = useState<DebugItem[]>();
	const [debugDateFrom, setDebugDateFrom] = useState( getNowStr(-3) );
	const [debugDateTo, setDebugDateTo] = useState( getNowStr() );

	const reset = () => {
		setIsSending(false)
		setResults(undefined);
	};

	const close = () => {
		reset();
		onClose();
	}
	
	const submit = async () => {
		if (isSending) {
			alert('Error: Previous request is in progress...');
			return;
		}
		if (tags.length === 0) {
			alert('Error: No tags selected');
			return;
		}
		if (!debugDateFrom || !debugDateTo) {
			alert('Error: Missing to or from date');
			return;
		}
		if (debugDateFrom >= debugDateTo) {
			alert('Error: "To" date must be after "From" date');
			return;
		}
		const numDays = (Number(new Date(debugDateTo)) - Number(new Date(debugDateFrom))) / 1000 / 60 / 60 / 24;
		if (numDays > debugTagsMaxDays) {
			alert(`Error: Date range must be smaller than ${debugTagsMaxDays} days`);
			return;
		}

		const request = {
			dataSource: dsName,
			tagsType: dsType,
			tags: tags,
			from: debugDateFrom,
			to: debugDateTo,
		};

		setIsSending(true);

		try {
			const response = await axios.post(`${API}/debug-tags`, request);
			if (response.status !== 200) {
				console.log('Error response:', response);
				alert('The server responded with an error status');
				setIsSending(false);
				setResults(undefined);
				return;
			}

			const data: DebugItem[] = response.data?.data;
			setResults(data);
		}
		catch (err) {
			console.log('Error sending debug-tags request:', err, request);
			alert('There was an error with the request');
			setResults(undefined);
		}

		setIsSending(false);
	};

	const buttons: DialogButton[] = [];
	if (results) {
		buttons.push({title: 'Reset', primary: true, onClick: reset});
	}
	else if (!isSending) {
		buttons.push({title: 'Submit', primary: true, onClick: submit});
	}

	buttons.push({title: 'Cancel', onClick: close});

    return (
        <Dialog
            title="Debug Tags"
            isOpen={isOpen}
            buttons={buttons}
            onCancel={close}
        >
			{isSending ? (
				<div className="text-center"><div className="spinner-border text-primary p-4 mx-auto" role="status"></div></div>
			) : results ? (
				<>
					<table className="table table-sm table-hover">
						<thead className="thead-light">
							<tr>
								<th>Timestamp</th>
								<th>Tag</th>
								<th>Value</th>
							</tr>
						</thead>
						<tbody>
							{results.map((item, idx) => {
								const tags = Object.keys(item).filter(key => key !== 'TS');

								return (
									<Fragment key={idx}>
										{tags.map(tag => (
											<tr key={tag}>
												<td>{formatTimestamp(item.TS)}</td>
												<td>{tag}</td>
												<td>{item[tag]}</td>
											</tr>
										))}
									</Fragment>
								);
							})}
						</tbody>
					</table>
				</>
			) : (
				<>
					<div className="my-3">{tags.length} tags selected.</div>
					<div className="my-3">
						<TextField type="datetime-local" label="From" value={debugDateFrom} onChange={(e) => setDebugDateFrom(e.target.value)} InputLabelProps={{shrink: true}} className="mr-4" />
						<TextField type="datetime-local" label="To" value={debugDateTo} onChange={(e) => setDebugDateTo(e.target.value)} InputLabelProps={{shrink: true}} />
					</div>
				</>
			)}
        </Dialog>
    );
}


function getNowStr(offsetDays = 0) {
	const now = new Date();
	now.setMinutes(now.getMinutes() - now.getTimezoneOffset() + offsetDays * (24 * 60));
	now.setMilliseconds(0); now.setSeconds(0);
	return now.toISOString().slice(0, -8);
}

function formatTimestamp(ts: string) {
	const date = new Date(ts);
	const pad = (n: number) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
	return date.getFullYear() +
	  '-' + pad(date.getMonth() + 1) +
	  '-' + pad(date.getDate()) +
	  ' ' + pad(date.getHours()) +
	  ':' + pad(date.getMinutes()) +
	  ':' + pad(date.getSeconds());
}