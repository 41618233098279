import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import axios from 'axios';
import Table from '../../core/Table/Table';
import Modal from '../../core/Modal/Modal';
import ShowDetails from '../../popups/ShowDetails/ShowDetails';
import AddProject from '../../popups/AddProject/AddProject';
import AddUsers from '../../popups/AddUsers/AddUsers';
import ShowDashboardList from '../../popups/ShowDashboardList/ShowDashboardList';
import RetroSelectDlg from '../../popups/RetroSelect/RetroSelectDlg';
import EditProjectDlg from '../../popups/EditProject/EditProjectDlg';
import ProjectUsers from './ProjectUsers';
import ProjectDataSources from './ProjectDataSources';
import { clearData } from '../../../store/actions/ProjectsActions';
import {
    getProjectList,
    getUsers,
    updateUsersToProject,
    getAdminDataSources,
    updateAdminDs,
    setAdminDataSourcesServer,
    clearAdminData
} from '../../../store/actions/AdminActions';
import { API, API_ADMIN } from '../../../config';


class Admin extends Component {

    state = {
        project: null,
        selectedProjects: [],
        users: [],
        searchValue: '',
        searchColumn: 'all',
        retroSelectOpen: false,
        isEditingProject: false,
        isLoading: false,
    }

    componentDidMount() {
        try {
            if (this.props.role !== 'admin') {
                return;
            }

            this.props.getProjectList();
            this.getUsers();
        }
        catch (err) {
            console.log('[Admin:componentDidMount] error:', err);
        }
    }

    async getUsers() {
        try {
            const {status, data: users} = await axios.get(`${API_ADMIN}/users`);
            if (status !== 200) {
                console.log(`[Admin:getUsers] failed requesting users. status code:`, status);
                return;
            }
            this.setState({users});
        }
        catch (err) {
            console.log(`[Admin:getUsers] error:`, err);    
        }
    }

    async updateProject(project) {
        this.setState({isEditingProject: false});
        if (!project || !project.name) { return; }

        try {
            this.setState({isLoading: true});
            await axios.post(`${API}/projects/${project.name}`, {project});
            await this.props.getProjectList();
        }
        catch (err) {
            console.log(`[Admin:updateProject] error:`, err);
        }
        finally {
            this.setState({isLoading: false});
        }
    }

    render() {
        const { getProjectList, getUsers, match, history, role, accountInfo } = this.props;
        const { path, url, params } = match;

        if (!role) {
            return <div className="spinner-border text-primary p-4 mx-auto"></div>;
        }
        else if (role !== 'admin') {
            return <Redirect to={{pathname: "/projects", state: {error: `User ${accountInfo.name} (${accountInfo.email}) is not authorized to access admin section`}}} />;
        }

        return (
            <div className="blueBackImage">
                <Switch>
                    <Route path={path} exact>
                        {this.displayProjectsTable()}
                    </Route>

                    <Route path={`${path}/projects/add`} exact>
                        <AddProject closePopup={() => {
                            getProjectList();
                            history.replace(`${url}`);
                        }} />
                    </Route>

                    <Route path={`${path}/projects/:projectName/dashboards`}>
                        {({match}) => (
                            <ShowDashboardList projectName={match.params.projectName} closePopup={() => history.replace(url)} />
                        )}
                    </Route>

                    <Route path={`${path}/projects/:projectName/details`}>
                        {({match}) => (
                            <ShowDetails projectName={match.params.projectName} closePopup={() => history.replace(url)} />
                        )}
                    </Route>

                    <Route path={`${path}/projects/:projectName/users/add`}>
                        {({match}) => (
                            <AddUsers
                                projectName={match.params.projectName}
                                closePopup={() => {
                                    getUsers(match.params.projectName);
                                    history.replace(`${url}/projects/${match.params.projectName}/users`);
                                }}
                            />
                        )}
                    </Route>

                    <Route path={`${path}/projects/:projectName/users`}>
                        <ProjectUsers {...this.props} />
                    </Route>

                    <Route path={`${path}/projects/:projectName/datasources`}>
                        <ProjectDataSources {...this.props} />
                    </Route>
                </Switch>
            </div>
        )
    }

    filterBySearch(projects) {
        const { searchValue, searchColumn } = this.state;
        if (!searchValue) { return projects; }

        const all = (searchColumn === 'all');
        const cleanSearchVal = searchValue.trim().toLowerCase();
        const colToProp = {
            'Project Name': 'name', Type: 'type', Contacts: 'contact_emails', Vendor: 'vendor_name',
            Description: 'description', Status: 'status', Comment: 'status_comment', 
            Manager: ['manager_name', 'manager_email']
        };

        projects = projects.filter(project => {
            for (const [column, propOrProps] of Object.entries(colToProp)) {
                if (all || searchColumn === column) {
                    const props = [].concat(propOrProps); // support multiple props per column
                    for (const prop of props) {
                        if (project[prop]?.toLowerCase().includes(cleanSearchVal)) { return true; }
                    }
                }
            }
        });

        return projects;
    }


    displayProjectsTable = () => {
        const { project, searchValue, searchColumn, selectedProjects, retroSelectOpen, isEditingProject, isLoading, users } = this.state;
        const { history, projects, getProjectList, clearAdminData, clearData, accountInfo } = this.props;

        if (!projects || isLoading) {
            return <div className="spinner-border text-primary p-4 mx-auto"></div>
        } else {
            const actions = [
                {name: 'Add Project', active: 'always', position: 'left', run: () => history.push(`/admin/projects/add`)},
                {name: 'Edit', active: 'single', showInTable: true, run: (project) => {
                    this.setState({isEditingProject: true, project});
                }},
                {name: 'Dashboards', active: 'single', run: (project) => {
                    clearData();
                    history.push(`/admin/projects/${project.name}/dashboards`);
                }}, 
                {name: 'Details', active: 'single', showInTable: true, run: (project) => {
                    clearData();
                    history.push(`/admin/projects/${project.name}/details`);
                }}, 
                {name: 'Users', active: 'single', run: (project) => {
                    clearAdminData();
                    history.push(`/admin/projects/${project.name}/users`);
                }}, 
                {name: 'Datasources', active: 'single', run: (project) => {
                    clearAdminData();
                    history.push(`/admin/projects/${project.name}/datasources`);
                }},
                {name: 'Retro Select', active: 'single', run: (project) => {
                    this.setState({retroSelectOpen: true, project});
                }},
                {name: 'Delete', active: 'single', run: (project) => {
                    this.setState({project});
                    /* eslint-env jquery */
                    $('#exampleModal').modal();
                }}, 
            ];

            const columns = [
                {title: 'Project Name', data: 'name', cellClass: 'font-weight-bold'},
                {title: 'Type', data: 'type'},
                {title: 'Manager', data: (proj) => `${proj.manager_email ?? ''}${proj.manager_name ? ` (${proj.manager_name})` : ''}`},
                {title: 'Contacts', data: 'contact_emails'},
                {title: 'Vendor', data: 'vendor_name'},
                {title: 'Description', data: 'description'},
                {title: 'Status', data: 'status'},
                {title: 'Comment', data: 'status_comment'},
            ];

            const filteredProjects = this.filterBySearch(projects);
            const filteredSelectedProjects = selectedProjects.filter(item => filteredProjects.includes(item));

            return (
                <>
                    <Table 
                        id="projects"
                        title={<>Projects ({filteredProjects.length}) <span className="table-subtitle ml-2">({filteredSelectedProjects.length} selected)</span></>}
                        items={filteredProjects}
                        columns={columns}
                        keyFn={({name}) => name}
                        selectedItems={selectedProjects}
                        setSelectedItems={(selectedProjects) => this.setState({selectedProjects})}
                        actions={actions}
                        pageSize={20}
                        searchValue={searchValue}
                        setSearchValue={(value) => this.setState({searchValue: value})}
                        searchColumn={searchColumn}
                        setSearchColumn={(value) => this.setState({searchColumn: value})}
                        searchPlaceholder="Search for projects..."
                    />

                    <Modal
                        title={'Are you sure?'}
                        projectName={project?.name}
                        deleteProjectFlag
                        update={() => getProjectList()}
                    />

                    <RetroSelectDlg
                        isOpen={retroSelectOpen} 
                        projectName={project?.name}
                        email={accountInfo.email}
                        onClose={() => this.setState({retroSelectOpen: false})}
                    />

                    <EditProjectDlg
                        isOpen={isEditingProject}
                        project={project}
                        users={users}
                        updateProject={(project) => this.updateProject(project)}
                        onClose={() => this.setState({isEditingProject: false})}
                    />
                </>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        projects: state.admin.allProjects,
        users: state.admin.users,
        dataSources: state.admin.adminDataSources,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getProjectList: () => dispatch(getProjectList()),
        getAdminDataSources: (projectName) => dispatch(getAdminDataSources(projectName)),
        getUsers: (projectName) => dispatch(getUsers(projectName)),
        updateUsersToProject: (userList, projectName) => dispatch(updateUsersToProject(userList, projectName)),
        clearData: () => dispatch(clearData()),
        setAdminDataSourcesServer: (dsList, projectName) => dispatch(setAdminDataSourcesServer(dsList, projectName)),
        clearAdminData: () => dispatch(clearAdminData())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)( withRouter(Admin) );
