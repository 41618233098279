import React, { useEffect, useState } from 'react';
import Modal from '../../core/Modal/Modal';
import TableNav from '../../core/TableNav/TableNav';
import Pagination from '../../core/Pagination/Pagination';
import { useHistory, useRouteMatch } from 'react-router-dom';


export default function ProjectDataSources(props) {
	const match = useRouteMatch();
	const history = useHistory();
	const {path, url, params} = match;
	const {projectName} = params;

	const [selectedDs, setSelectedDs] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	
	const { dataSources, getAdminDataSources, setAdminDataSourcesServer } = props;

	const filteredDataSources = searchValue.length > 0 
		? dataSources.filter(ds => ds.name.toLowerCase().includes(searchValue.toLowerCase())) 
		: dataSources;


	useEffect(() => {
		getAdminDataSources(projectName);
	}, []);

	useEffect(() => {
		if (dataSources) {
			setSelectedDs( dataSources.filter(ds => ds.enabled).map(ds => ds.name) );
		}
	}, [dataSources]);


	const addDsToList = (datasource) => {
    if (selectedDs.some(ds => ds === datasource.name)) {
      setSelectedDs(selectedDs.filter(ds => ds !== datasource.name));
    } else {
			setSelectedDs([...selectedDs, datasource.name]);
    }
  };

	const updateDataSources = () => {
		const toUpdate = dataSources.map(ds => {
				return {
						project: projectName,
						ds: ds.name,
						enabled: selectedDs.includes(ds.name)
				}
		});
		setAdminDataSourcesServer(toUpdate, projectName);
	}


	return (
		<div className="tableContainer">
				<TableNav
						admin
						dsFlag
						primary={projectName}
						goToStart={() => { setSelectedDs([]); history.push(`/admin`); }}
						clickHandler={updateDataSources}
						clickHandler2={() => getAdminDataSources(projectName)}
						searchHandler={(input) => setSearchValue(input)}
				/>
				<div className="col p-0">
						<div className="lightGrey">
								<div>
										<div className="tableRow">
												<div className="text-left p-3 pointer tableText font-weight-bold">
														Data Sources - {projectName}
												</div>
										</div>
										<div className="innerTableContainer">
												{!dataSources ?
														<div className="spinner-border text-primary p-3 mx-auto" role="status"></div>
														:
														dataSources.length === 0 ?
																<h4 className="m-0 p-5 text-center">There are no data sources in this project yet</h4>
																:
																<Pagination
																		list={dataSources}
																		isSelected={true}
																		deleteClicked={true}
																		selectedToDelete={selectedDs}
																		addToDeleteList={addDsToList}
																		checkboxStatus="adminDs"
																/>
												}
										</div>
								</div>
						</div>
				</div>

				<Modal
						title={'The data sources have been successfully updated'}
						simple
				/>
		</div>
	);
}