import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Button from '../../core/Button/Button';
import { addUsersToProject } from '../../../store/actions/AdminActions';

class AddUsers extends Component {

    state = {
        emailsInput: '',
        isValid: true,
        emailsToAdd: [],
        saveClicked: false,
        isLoading: false,
        success: false,
    }

    render() {
        const { emailsToAdd, emailsInput, isValid, isLoading, success, saveClicked } = this.state;
        const { closePopup, projectName } = this.props;
        if (isLoading) {
            return <div className="spinner-border text-primary p-3 mx-auto" role="status"></div>
        } else {
            return (
                <div className="popup" style={{ width: '700px' }}>
                    {success ?
                        <div className="title mx-4 d-flex justify-content-center align-items-center">
                            <img className="pointer arrow-back" src={require('../../../assets/images/backArrow.png')} alt="back-arrow"
                                onClick={() => {
                                    this.setState({ success: false })
                                    closePopup(emailsToAdd)
                                }} />
                            <h1 className="text-center">
                                Successful saving users
                            </h1>
                        </div>
                        :
                        <div className="content-team">
                            <div className="title mx-4 mb-5 d-flex justify-content-center align-items-center">
                                <img className="pointer arrow-back" src={require('../../../assets/images/backArrow.png')} alt="back-arrow" onClick={() => closePopup()} />
                                <h1 className="text-center">Add users</h1>
                            </div>
                            <div className="input-label text-center mb-3">
                                <div className="font-weight-bold">Project: {projectName}</div>
                            </div>
                            <div className="container">
                                <div className="input-label mt-2">Email addresses</div>
                                <div className="insert-container scrollY bg-light p-1" style={{ height: '200px', width: '600px' }} onClick={() => this.setState({ selected: 'insert' })}>
                                    <div className="d-flex flex-wrap align-items-center">
                                        {emailsToAdd ?
                                            emailsToAdd.map((email, index) => (
                                                <div key={index} className="mr-1 mt-4" style={{ borderRadius: '20px' }}>
                                                    <Chip
                                                        size={'small'}
                                                        label={`${email}`}
                                                        variant={'outlined'}
                                                        color={'primary'}
                                                        onDelete={() => this.removeEmailHandler(email)}
                                                    />
                                                </div>
                                            ))
                                            : null}
                                        <div className="ml-2 d-flex flex-column">
                                            <TextField
                                                id="standard-basic"
                                                label="Email"
                                                value={emailsInput}
                                                style={{ width: '220px' }}
                                                onChange={(event) => this.setState({ emailsInput: event.target.value, isValid: true })}
                                                onBlur={(event) => this.insertEmailHandler(event, 'blur')}
                                                onKeyDown={(event) => this.insertEmailHandler(event, 'keyDown')}
                                            />
                                            {isValid ? null : <div className="text-left" style={{ color: '#dc3545', fontSize: '80%' }}>Please provide a valid email</div>}
                                        </div>
                                    </div>
                                </div>
                                {saveClicked && emailsToAdd.length === 0 && emailsInput.length === 0 ? <div className="text-center" style={{ color: '#dc3545' }}>You must enter email addresses</div> : null}
                            </div>
                            <div className="mt-4 d-flex justify-content-center">
                                <div className="mr-2">
                                    <Button title={"SAVE"} clickHandler={() => this.setState({ saveClicked: true }, () => this.saveHandler())}></Button>
                                </div>
                                <div>
                                    <Button outlineBlue title={"CANCEL"} clickHandler={() => closePopup()}></Button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            );
        }
    }

    insertEmailHandler = (event, type) => {
        const newEmail = event.target.value;
        if (type === 'keyDown') {
            if (event.which === 13 || event.which === 32 || event.which === 188) {            
                if (!this.validateEmail(newEmail)) {
                    this.setState({ isValid: false })
                } else {
                    this.setState(prevState => {
                        return {
                            emailsToAdd: [...prevState.emailsToAdd, newEmail],
                            emailsInput: '',
                            isValid: true
                        }
                    }, () => console.log())
                }
            }
        } else if (type === 'blur') {
            if (!this.validateEmail(newEmail)) {
                this.setState({ isValid: false })
            } else {
                this.setState(prevState => {
                    return {
                        emailsToAdd: [...prevState.emailsToAdd, newEmail],
                        emailsInput: '',
                        isValid: true
                    }
                }, () => console.log())
            }
        }
    }

    insertEmailHandler2 = (event, type) => {

    }

    validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    removeEmailHandler = (email) => {
        const updatedEmails = this.state.emailsToAdd.filter(e => e !== email)
        this.setState({ emailsToAdd: updatedEmails })
    }

    saveHandler = async () => {
        const { emailsToAdd, saveClicked } = this.state;
        const { projectName, addUsersToProject } = this.props;
        if (saveClicked && emailsToAdd.length > 0) {
            this.setState({ isLoading: true })
            const response = await addUsersToProject(emailsToAdd, projectName)
            this.setState({
                isLoading: false,
                success: true,
            })
        }
        // closePopup()
        // this.forceUpdate()
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addUsersToProject: (usersList, projectName) => dispatch(addUsersToProject(usersList, projectName)),
    }
}

export default connect(null, mapDispatchToProps)(AddUsers);

