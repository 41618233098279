import React, { Component } from 'react';
import Button from '../Button/Button';
import { connect } from 'react-redux';
import { deleteTagsHandler, unsubscribeHandler, deleteGroupHandler } from '../../../store/actions/ProjectsActions';
import { deleteProjectHandler } from '../../../store/actions/AdminActions';

class Modal extends Component {

  state = {
    isDeleting: false,
    deleteSuccess: '',
    errorMessage: '',
  }

  render() {
    const { isDeleting, deleteSuccess, errorMessage } = this.state;
    const { title, simple, update } = this.props;
    return (
      <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {isDeleting ?
              <div className="modal-header border-0 justify-content-center p-2">
                <div className="spinner-border text-primary p-4 mx-auto" role="status"></div>
              </div>
              :
              <div className="modal-header border-0 justify-content-center p-2">
                <h5 className="modal-title mt-3 lightBlue title" id="exampleModalLabel">
                  {deleteSuccess === '' ? `${title}` : deleteSuccess ? 'The deletion was successful' : `Something went wrong - ${errorMessage}.`}
                </h5>
              </div>
            }
            <div>
              {simple ?
                <div className="modal-footer border-0 justify-content-center p-2">
                  <Button title={'OK'} dataDismiss={'modal'} clickHandler={() => this.setState({ deleteSuccess: '' })} />
                </div>
                :
                deleteSuccess ?
                  <div className="modal-footer border-0 justify-content-center p-2">
                    <Button
                      title={'OK'}
                      outlineBlue
                      dataDismiss={'modal'}
                      clickHandler={() => {
                        this.setState({ deleteSuccess: '' }, () => update(true))

                      }}
                    />
                  </div>
                  :
                  deleteSuccess === '' ?
                    <div className="modal-footer border-0 justify-content-center p-2">
                      <Button title={'CANCEL'} outlineBlue dataDismiss={'modal'} clickHandler={() => this.setState({ deleteSuccess: '' })} />
                      <Button
                        title={'CONFIRM'}
                        clickHandler={() => this.deleteHandler()}
                      />
                    </div>
                    :
                    <div className="modal-footer border-0 justify-content-center p-2">
                      <Button title={'CANCEL'} outlineBlue dataDismiss={'modal'} clickHandler={() => this.setState({ deleteSuccess: '' })} />
                    </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  deleteHandler = () => {
    const {
      deleteTagsHandler,
      unsubscribeHandler,
      projectName,
      dsName,
      tableName,
      tags,
      groupName,
      emails,
      deleteProjectFlag,
      deleteProjectHandler,
      deleteGroupFlag,
      deleteGroupHandler,
      users,
      deleteUsers
    } = this.props;

    if (tags) {
      this.setState({ isDeleting: true })
      deleteTagsHandler(projectName, dsName, tableName, tags)
        .then(res => this.setState({ deleteSuccess: true, isDeleting: false }))
        .catch(err => this.setState({ deleteSuccess: false, isDeleting: false, errorMessage: err.response.data }))
    } else if (emails) {
      this.setState({ isDeleting: true })
      unsubscribeHandler(projectName, groupName, emails)
        .then(res => this.setState({ deleteSuccess: true, isDeleting: false }))
        .catch(err => this.setState({ deleteSuccess: false, isDeleting: false, errorMessage: err.response.data }))
    } else if (users) {
      this.setState({ deleteSuccess: true, isDeleting: false })
      deleteUsers()
    } else if (deleteProjectFlag) {
      this.setState({ isDeleting: true })
      deleteProjectHandler(projectName)
        .then(res => this.setState({ deleteSuccess: true, isDeleting: false }))
        .catch(err => this.setState({ deleteSuccess: false, isDeleting: false, errorMessage: err.response.data }))
    } else if (deleteGroupFlag) {
      this.setState({ isDeleting: true })
      deleteGroupHandler(projectName, groupName)
        .then(res => this.setState({ deleteSuccess: true, isDeleting: false }))
        .catch(err => this.setState({ deleteSuccess: false, isDeleting: false, errorMessage: err.response.data }))
    }
  }

}


const mapDispatchToProps = dispatch => {
  return {
    deleteTagsHandler: (projectName, dsName, tableName, tags) => dispatch(deleteTagsHandler(projectName, dsName, tableName, tags)),
    unsubscribeHandler: (projectName, groupName, emailList) => dispatch(unsubscribeHandler(projectName, groupName, emailList)),
    deleteProjectHandler: (projectName) => dispatch(deleteProjectHandler(projectName)),
    deleteGroupHandler: (projectName, groupName) => dispatch(deleteGroupHandler(projectName, groupName)),
  }
}

export default connect(null, mapDispatchToProps)(Modal);