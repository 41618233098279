import {
    SET_PROJECTS,
    SET_SELECTED_PROJECT_DETAILS,
    SET_DS,
    SET_PERMISSIONS,
    SET_GROUPS,
    SET_GROUP_EMAILS,
    SET_DASHBOARDS,
    CLEAR_DATA,
} from '../actions/ProjectsActions'

const initialState = {
    projects: null,
    projectDetails: null,
    dataSources: null,
    dataSourcesFull: null,
    groups: null,
    groupEmails: null,
    dashboards: null,
}

const ProjectsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROJECTS:
            return {
                ...state,
                projects: action.projects
            }
        case SET_SELECTED_PROJECT_DETAILS:
            return {
                ...state,
                projectDetails: action.details
            }
        case SET_DS:
            let customLDs;
            if (action.dataSources) {
                customLDs = action.dataSources.map(ds => ds.historian_type)
            }
            return {
                ...state,
                dataSources: customLDs,
                dataSourcesFull: action.dataSources
            }
        case SET_PERMISSIONS:
            return {
                ...state,
                permissions: action.permissions
            }
        case SET_GROUPS:
            return {
                ...state,
                groups: action.groups,
            }
        case SET_GROUP_EMAILS:
            return {
                ...state,
                groupEmails: action.emails,
            }
        case SET_DASHBOARDS:
            return {
                ...state,
                dashboards: action.dashboards
            }
        case CLEAR_DATA:
            return {
                projects: action.alsoProjects ? null : state.projects,
                projectDetails: {},
                dataSources: null,
                dataSourcesFull: null,
                groups: null,
                groupEmails: null,
                dashboards: null,
            }
        default:
            return state
    }
}

export default ProjectsReducer;