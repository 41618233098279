import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getDashboardList } from '../../../store/actions/ProjectsActions';


class ShowDashboardList extends Component {

    state = {
        iframeUrl: null
    }

    async componentDidMount() {
        const { projectName, getDashboardList, accountInfo } = this.props;
        try {
            await getDashboardList(projectName)
        }
        catch (err) {
            console.log('[ShowDashboardList] getDashboardList error:', err);
            this.props.history.replace('/projects', {error: `User ${accountInfo.email} is not authorized to access project ${projectName}`});
        }
    }

    clickHandler = (url) => {
        this.setState({ iframeUrl: url })
    }

    displayDashboardTable = () => {
        const { dashboards } = this.props;
        return (
            <table className="table table-bordered mt-4">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Description</th>
                    </tr>
                </thead>
                {dashboards && dashboards.length === 0 ?
                    <tbody className="lightGrey text-center">
                        <h4 className="m-0 p-5">There are no dashboards yet</h4>
                        <th scope="col" className="text-left py-3 px-3"></th>
                    </tbody>
                    :
                    <tbody>
                        {dashboards.map((dashboard, index) => (
                            <tr key={index}>
                                <td className="whiteBack">
                                    <a className="nav-link" style={{ cursor: 'pointer' }} onClick={() => this.clickHandler(dashboard.url)}>{dashboard.name}</a></td>
                                <td className="whiteBack">{dashboard.description}</td>
                            </tr>
                        ))}
                    </tbody>
                }
            </table>
        )
    }

    render() {
        const { dashboards, closePopup } = this.props;
        const { iframeUrl } = this.state;
        if (!dashboards) {
            return (
                <div className="popup" style={{ paddingBottom: 0, minHeight: '420px' }}>
                    <div className="title mx-4 d-flex justify-content-center align-items-center">
                        <img className="pointer arrow-back" src={require('../../../assets/images/backArrow.png')} alt="back-arrow" onClick={() => closePopup()} />
                        <h1 className="text-center">Dashboards</h1>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="spinner-border text-primary p-4 mx-auto mb-3 mt-5" role="status"></div>
                    </div>
                </div>
            )
        } else {
            if (iframeUrl) {
                return (
                    <div className="popup" style={{ paddingBottom: 0, minHeight: '420px', backgroundColor: 'transparent' }}>
                        <div className="title mx-4 d-flex justify-content-center align-items-center">
                            <img style={{ position: 'absolute', top: '100px',  zIndex: 1111 }} className="pointer arrow-back" src={require('../../../assets/images/backArrow.png')} alt="back-arrow" onClick={() => closePopup()} />
                        </div>
                        <iframe src={iframeUrl} height="600" width="800"></iframe>
                    </div>
                )
            } else {
                return (
                    <div className="popup" style={{ paddingBottom: 0, minHeight: '420px' }}>
                        <div className="title mx-4 d-flex justify-content-center align-items-center">
                            <img className="pointer arrow-back" src={require('../../../assets/images/backArrow.png')} alt="back-arrow" onClick={() => closePopup()} />
                            <h1 className="text-center">Dashboards</h1>
                        </div>

                        { this.displayDashboardTable() }
                    </div >
                )
            }
        }
    }
}

const mapStateToProps = state => {
    return {
        dashboards: state.projects.dashboards,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDashboardList: (projectName) => dispatch(getDashboardList(projectName)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)( withRouter(ShowDashboardList) );