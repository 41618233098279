import React, { useEffect } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import TableRow from '../../core/TableRow/TableRow';
import TableNav from '../../core/TableNav/TableNav';
import AddTags from '../../popups/AddTags/AddTags';
import AddGroup from '../../popups/AddGroup/AddGroup';
import ShowDetails from '../../popups/ShowDetails/ShowDetails';
import ShowDashboardList from '../../popups/ShowDashboardList/ShowDashboardList';
import Groups from './Groups';
import Group from './Group';
import DataSourceTags from './DataSourceTags';


export default function Project(props) {
	const match = useRouteMatch();
	const history = useHistory();
	const {path, url, params} = match;
	const {projectName} = params;
	
	return (
		<Switch>
			<Route path={path} exact>
				<DataSourcesList {...props} />
			</Route>

			<Route path={`${path}/dashboards`}>
				<ShowDashboardList {...props} projectName={projectName} closePopup={() => history.replace(url)} />
			</Route>

			<Route path={`${path}/details`}>
				<ShowDetails {...props} projectName={projectName} closePopup={() => history.replace(url)} />
			</Route>

			<Route path={`${path}/distributions/add`}>
				<AddGroup {...props}
					projectName={projectName}
					closePopup={() => history.replace(`${url}/distributions`)}
				/>
			</Route>

			<Route path={`${path}/distributions/:groupName/add`}>
				{({match}) => (
					<AddGroup {...props}
						projectName={projectName}
						closePopup={() => history.replace(`${url}/distributions/${match.params.groupName}`)}
						editGroupFlag={true}
						groupToEdit={match.params.groupName}
					/>
				)}
			</Route>

			<Route path={`${path}/distributions/:groupName`}>
				<Group {...props} />
			</Route>

			<Route path={`${path}/distributions`}>
				<Groups {...props} />
			</Route>

			<Route path={`${path}/ds/:dsName/:dsType/tags/add`}>
				{({match}) => (
					<AddTags
						projectName={projectName}
						dsName={match.params.dsName}
						tableName={match.params.dsType}
						closePopup={() => history.replace(`${url}/ds/${match.params.dsName}/${match.params.dsType}`)}
					/>
				)}
			</Route>

			<Route path={`${path}/ds/:dsName/:dsType`}>
				<DataSourceTags {...props} />
			</Route>
		</Switch>
	);
}


function DataSourcesList({dataSources, getDataSources, accountInfo}) {
	const match = useRouteMatch();
	const history = useHistory();
	const { projectName } = match.params;

	useEffect(() => {
		run(); 

		async function run() {
			try {
				await getDataSources(projectName);
			}
			catch (err) {
				console.log('[Project:DataSourcesList] getDataSources error:', err);
				history.replace('/projects', {error: err.response?.status === 403 ? `User ${accountInfo.email} is not authorized to access project ${projectName}` : (err.message ?? 'Server error')});
			}
		}
	}, []);

	if (!dataSources) {
		return <div className="spinner-border text-primary p-4 mx-auto" role="status"></div>
	} else {
		return (
			<div className="tableContainer">
				<TableNav
					title={'Data Sources'}
					dsFlag
					primary={projectName}
					goToStart={() => history.push('/projects')}
					setShowPopup={() => history.push(`${match.url}/details`)}
					clickHandler={() => history.push(`${match.url}/distributions`)}
					clickHandler2={() => history.push(`${match.url}/dashboards`)}
				/>
				{dataSources.length === 0 ?
					<div className="table">
						<div className="lightGrey text-center">
							<h4 className="m-0 p-5">There are no data sources in this project yet</h4>
						</div>
					</div>
					:
					<div className="table">
						<div className="lightGrey">
							{dataSources.map((ds, index) => {
								return (
									<div key={index}>
										<TableRow
											dsFlag
											item={ds}
											clickHandler={(table) => history.push(`/projects/${projectName}/ds/${ds}/${table.type}`)}  
											projectName={projectName}
										/>
									</div>
								)
							})}
						</div>
					</div>
				}
			</div>
		)
	}
}
