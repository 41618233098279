import React, { useState } from 'react';
import Dialog from '../Dialog/Dialog';
import './Table.css';

export type Column<ItemType> = {
	title: string;
	data: string | ((item: ItemType) => React.ReactNode);
	onClick?: (item: ItemType) => void;
	titleClass?: string;
	cellClass?: string;
};

export type Action<ItemType> = {
	name: string;
	run: (itemOrItems: ItemType | ItemType[]) => void;
	active?: 'single' | 'multiple' | 'always';
	position?: 'menu' | 'left' | 'right';
	showInTable?: boolean;
};

type TableTopBarProps<ItemType> = {
	id?: string; 	// required for persisting hidden columns to localStorage
	title?: React.ReactNode;
	columns: Column<ItemType>[];
	hiddenColumns: string[];
	setHiddenColumns: (cols: string[]) => void;
	actions?: Action<ItemType>[];
	selectedItems: ItemType[];
	searchValue: string;
	setSearchValue: (value: string) => void;
	searchColumn?: string;
	setSearchColumn?: (value: string) => void;
	searchPlaceholder?: string;
};

function TopBar<ItemType>(props: TableTopBarProps<ItemType>) {
	let {
		id, title, columns, hiddenColumns, setHiddenColumns, 
		actions, selectedItems, searchValue, setSearchValue, searchColumn, setSearchColumn, searchPlaceholder,
	} = props;

	const [isSelectingColumns, setIsSelectingColumns] = useState(false);

	actions = actions?.slice() ?? [];
	if (columns.length > 1) {
		actions.push({name: 'Select columns', run: (item) => { setIsSelectingColumns(true); }});
	}

	function ActionButton({name, run, active = 'always', position = 'menu'}: Action<ItemType>) {
		const disabled = (active === 'always')
			? false
			: (selectedItems.length === 0 || (selectedItems.length > 1 && active === 'single'));

		const className = (position === 'menu') ? 'dropdown-item' : 'btn btn-outline-brand mx-3';

		return (
			<button type="button"
				className={`${className} ${disabled ? 'disabled' : ''}`}
				disabled={disabled}
				onClick={() => run(active === 'single' ? selectedItems[0] : selectedItems)}
			>
				{name}
			</button>
		);
	}

	function toggleColumn(column: Column<ItemType>, isVisible: boolean) {
		let newHiddenCols: string[];
		if (isVisible) {
			newHiddenCols = hiddenColumns.filter(title => title !== column.title);
		}
		else {
			newHiddenCols = hiddenColumns.concat( column.title );
			if (newHiddenCols.length === columns.length) { return; }
		}
		setHiddenColumns(newHiddenCols);

		const storageKey = `table:hidden-cols:${window.location.pathname}:${id ?? ''}`;
		localStorage.setItem(storageKey, JSON.stringify(newHiddenCols));
	}

	return (
		<div className="table-topbar">
			<div className="table-header">
				<div className="table-title">{title}</div>

				<div className="table-actions">
					{actions.filter(a => a.position === 'left').map((action, idx) => (
						<ActionButton {...action} key={`left-${idx}`} />
					))}

					{actions.some(({position}) => !position || position === 'menu') && (
						<div className="dropdown">
							<button className="btn btn-outline-brand dropdown-toggle" type="button" data-toggle="dropdown">Actions</button>

							<div className="dropdown-menu dropdown-menu-right">
								{actions.filter(a => !a.position || a.position === 'menu').map((action, idx) => (
									<ActionButton {...action} key={`menu-${idx}`} />
								))}
							</div>
						</div>
					)}

					{actions.filter(a => a.position === 'right').map((action, idx) => (
						<ActionButton {...action} key={`right-${idx}`} />
					))}
				</div>
			</div>

			<div className="my-3 form-inline">
				<input value={searchValue} onChange={(e) => setSearchValue(e.target.value)} 
					type="text" placeholder={searchPlaceholder ?? "Search..."} className="form-control form-control-sm table-search" />

				{(searchColumn && setSearchColumn) && (
					<select className="form-control form-control-sm ml-2" value={searchColumn} onChange={(e) => setSearchColumn?.(e.target.value)}>
						<option value="all">All fields</option>
						{columns.map((col, idx) => (
							<option key={idx} value={col.title}>{col.title}</option>
						))}
					</select>
				)}
			</div>

			<Dialog title="Select columns" 
				isOpen={isSelectingColumns} 
				onCancel={() => setIsSelectingColumns(false)} 
				buttons={[{title: 'Done', onClick: () => setIsSelectingColumns(false)}]}
			>
				<div className="d-flex flex-wrap">
					{columns.map(col => (
						<div key={col.title} className="select-cols-item">
							<label>
								<input type="checkbox" checked={!hiddenColumns.includes(col.title)} onChange={(e) => toggleColumn(col, e.target.checked)} />
								<span className="ml-1">{col.title}</span>
							</label>
						</div>
					))}
				</div>
			</Dialog>
		</div>
	);
}


type TableBodyProps<ItemType> = {
	items: ItemType[];
	selectedItems: ItemType[];
	setSelectedItems: (items: ItemType[]) => void;
	columns: Column<ItemType>[];
	hiddenColumns: string[];
	keyFn?: (item: ItemType, idx: number) => React.Key | null | undefined;
	actions: Action<ItemType>[];
	searchValue: string;
	pageSize?: number;
	currPage?: number;
};

function TableBody<ItemType>({items, selectedItems, setSelectedItems, columns, hiddenColumns, keyFn, actions, searchValue, pageSize, currPage}: TableBodyProps<ItemType>) {

	let pageItems = items;
	
	if (pageSize && currPage !== undefined) {
		pageItems = items.slice(currPage * pageSize, (currPage + 1) * pageSize);
	}

	columns = columns.filter(col => !hiddenColumns.includes(col.title));

	const selectItem = (item: ItemType, selected: boolean) => {
		if (selected) {
			setSelectedItems( selectedItems.concat(item) );
		}
		else {
			setSelectedItems( selectedItems.filter(i => i !== item) );
		}
	};

	return (
		<table className="table">
			<thead>
				<tr>
					<th className="table-checkbox">
						<input type="checkbox" 
							checked={items.length > 0 && selectedItems.length >= items.length} 
							onChange={(e) => setSelectedItems( e.target.checked ? items : [] )}
						/>
					</th>

					{columns.map((col, idx) => (
						<th key={idx} className={`py-3 textMuted ${col.titleClass ?? ''}`}>{col.title}</th>
					))}

					{actions.filter(a => a.showInTable).map((action) => <th key={action.name} className="col-fit-content"></th>)}
				</tr>
			</thead>

			<tbody className="lightGrey">
				{items.length === 0 && (
					<tr>
						<td colSpan={columns.length + 1}>
							<h4 className="m-0 p-5 text-center">There are no items {searchValue.length === 0 ? "yet" : "matching the search query"}</h4>
						</td>
					</tr>
				)}

				{pageItems.map((item, idx) => (
					<tr key={keyFn?.(item, idx) ?? idx}>
						<td className="table-checkbox">
							<input type="checkbox" 
								checked={selectedItems.includes(item)} 
								onChange={(e) => selectItem(item, e.target.checked)} />
						</td>

						{columns.map((col, idx) => {
							let extraProps;
							if (col.onClick) {
								extraProps = {onClick: () => col.onClick?.(item)};
							}

							return (
								<td key={idx} {...extraProps} className={`tableText ${col.cellClass ?? ''}`}>
									{typeof col.data === 'string' ? item[col.data as keyof ItemType] : col.data(item)}
								</td>
							);
						})}

						{actions.filter(a => a.showInTable).map((action) => (
							<td key={action.name} className="py-0 px-1">
								<button type="button" className="button" onClick={() => action.run(item)}>
									{action.name}
								</button>
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
}


const chevron = (<svg viewBox="0 0 24 24"><path d="M5.519 8.9l6.48 8.1 6.481-8.1h-2.56L12 13.798l-3.92-4.9z"></path></svg>);

type PaginationProps = {
	numItems: number;
	pageSize?: number;
	currPage: number;
	setCurrPage: (page: number) => void;
};

function Pagination({numItems, pageSize, currPage, setCurrPage}: PaginationProps) {
	if (numItems === 0 || !pageSize) { return null; }

	const numPages = Math.ceil(numItems / pageSize);
	const prevEnabled = (currPage > 0);
	const nextEnabled = (currPage < numPages - 1);

	return (
		<div className="row justify-content-center py-2">
			<div className="col-sm-10 col-md-8 col-lg-6 col-xl-4">
				<div className="table-pagination">
					<div className={prevEnabled ? "pointer" : "faded cursor-default"} onClick={() => prevEnabled && setCurrPage(currPage - 1)}>
						<div className="icon-svg rotate-90">{chevron}</div> Previous
					</div>

					<div className="flex-grow-1 pagination-text">{currPage + 1} of {numPages}</div>

					<div className={nextEnabled ? "pointer" : "faded cursor-default"} onClick={() => nextEnabled && setCurrPage(currPage + 1)}>
						Next <div className="icon-svg rotate-270" >{chevron}</div>
					</div>
				</div>
			</div>
		</div>
	);
}


export type TableProps<ItemType> = TableTopBarProps<ItemType> & TableBodyProps<ItemType> & {
	className?: string;
};

export default function Table<ItemType>(props: TableProps<ItemType>) {
	const {
		id, title, actions, items, selectedItems, setSelectedItems, columns, keyFn,
		className, searchValue, setSearchValue, searchPlaceholder, searchColumn, setSearchColumn, pageSize
	} = props;

	const [currPage, setCurrPage] = useState(0);
	const [hiddenColumns, setHiddenColumns] = useState<string[]>(() => {
		const storageKey = `table:hidden-cols:${window.location.pathname}:${id ?? ''}`;
		return JSON.parse( localStorage.getItem(storageKey) ?? '[]' );
	});

	const filteredSelectedItems = selectedItems.filter(item => items.includes(item));

	return (
		<div className={`table-container my-2 ${className ?? ''}`}>
			<TopBar 
				id={id}
				title={title} 
				columns={columns}
				hiddenColumns={hiddenColumns} setHiddenColumns={setHiddenColumns}
				actions={actions}
				selectedItems={filteredSelectedItems} 
				searchValue={searchValue} setSearchValue={(value) => { setSearchValue(value); setCurrPage(0); }} 
				searchColumn={searchColumn}
				setSearchColumn={setSearchColumn}
				searchPlaceholder={searchPlaceholder}
			/>

			<TableBody 
				items={items}
				selectedItems={filteredSelectedItems} setSelectedItems={setSelectedItems} 
				columns={columns}
				hiddenColumns={hiddenColumns}
				keyFn={keyFn}
				actions={actions}
				searchValue={searchValue}
				pageSize={pageSize}
				currPage={currPage}
			/>

			<Pagination
				numItems={items.length}
				pageSize={pageSize}
				currPage={currPage} setCurrPage={setCurrPage}
			/>
		</div>
	);
}