import { 
    SET_ALL_PROJECTS, 
    SET_USERS, 
    SET_ADMIN_DS, 
    UPDATE_ADMIN_DS, 
    SET_ADMIN_DS_SERVER, 
    ADD_USER_TO_PROJECT, 
    UPDATE_USER_TO_PROJECT, 
    SEARCH_USERS,
    CLEAR_ADMIN_DATA 
} from '../actions/AdminActions'

const initialState = {
    allProjects: null,
    users: null,
    filteredUsers: null,
    adminDataSources: null,
}

const AdminReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_PROJECTS:
            return {
                ...state,
                allProjects: action.projects
            }
        case SET_USERS:
            let list;
            if (action.users) {
                list = action.users.map(projectUser => projectUser.user)
            }
            return {
                ...state,
                users: list,
                filteredUsers: list
            }
        case SEARCH_USERS:
            let filtered = [...state.users];
            filtered = filtered.filter(user => {
                return user.toLowerCase().includes(action.input.toLowerCase());
            })
            return {
                ...state,
                filteredUsers: filtered
            }
        case SET_ADMIN_DS:
            return {
                ...state,
                adminDataSources: action.adminDataSources,
            }
        case UPDATE_ADMIN_DS:
            return {
                ...state,
                updatedAdminDataSources: action.updatedAdminDs
            }
        case SET_ADMIN_DS_SERVER:
            return {
                ...state,
                adminDataSourcesResponse: action.adminDataSourcesResponse
            }
        case ADD_USER_TO_PROJECT:
            return {
                ...state,
                addUsersToProjectResponse: action.addUsersToProjectResponse
            }
        case UPDATE_USER_TO_PROJECT:
            return {
                ...state,
                updateUsersToProjectResponse: action.updateUsersToProjectResponse
            }
        case CLEAR_ADMIN_DATA:
            return {
                allProjects: action.alsoProjects ? null : state.allProjects,
                users: null,
                filteredUsers: null,
                adminDataSources: null,
                projectDetails: {}
            }
        default:
            return state
    }
}

export default AdminReducer;