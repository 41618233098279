import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';
import { clientBaseUrl } from '../../config';

export const adalConfig = {
  redirectUri: clientBaseUrl,
  tenant: '802762d2-02c4-4677-98ba-54060a234204',
  clientId: '29de178c-bc4b-4e42-88e8-48604cb2ebe1',
  endpoints: {
    api: '29de178c-bc4b-4e42-88e8-48604cb2ebe1',
    // api: 'https://login.microsoftonline.com/802762d2-02c4-4677-98ba-54060a234204',
    // token_endpoint:"https://login.microsoftonline.com/802762d2-02c4-4677-98ba-54060a234204/oauth2/token"
  },
  cacheLocation: 'localStorage',
  // extraQueryParameter: 'prompt=admin_consent'
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const WithAdalLoginAPI = withAdalLogin(authContext, adalConfig.endpoints.api);

// // Msal Configurations
// const config = {
//     auth: {
//        authority:'https://login.microsoftonline.com/802762d2-02c4-4677-98ba-54060a234204',
//         // clientId: 'a5c565a5-777e-456a-9917-5d50c47a55e8',
//         clientId: '29de178c-bc4b-4e42-88e8-48604cb2ebe1',
//         tenant: '802762d2-02c4-4677-98ba-54060a234204',
//         redirectUri: 'https://dlui.appsb.icldig.icl-group.com/',
//         postLogoutRedirectUri: 'http://localhost:3000',
//         popUp: false,
//       //  accessTokenAcceptedVersion: 2
        
//     },
//     cache: {
//         cacheLocation: "localStorage",
//         storeAuthStateInCookie: true
//     }
// };


// // Authentication Parameters
// const authenticationParameters = {
//     // scopes: [
//     //     'openid',
//     // ]
//     // scopes: ['https://graph.microsoft.com' + '/openid', 'profile', 'user.read'],
//     scopes: ['openid'],

// }

// // Options
// const options = {
//     loginType: LoginType.Popup,
//     tokenRefreshUri: window.location.origin + '/auth.html'
// }

// export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)
