import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getProjectDetails } from '../../../store/actions/ProjectsActions';

class ShowDetails extends Component {

    state = {
        selectedTab: 'Prod'
    }

    async componentDidMount() {
        const { getProjectDetails, projectName, details, accountInfo } = this.props;
        if(!details) {
            try {
                const result = await getProjectDetails(projectName);
                if (result.data === 'data not found') {
                    this.props.history.replace('/projects', {error: 'Error while getting project details from server'});
                    return;
                }
			}
			catch (err) {
				console.log('[ShowDetails] getProjectDetails error:', err);
				this.props.history.replace('/projects', {error: `User ${accountInfo.email} is not authorized to access project ${projectName}`});
			}
        }
    }

    render() {
        const { closePopup, projectDetails, details } = this.props;
        const { selectedTab } = this.state;
        const detailsToRender = details ? details : projectDetails

        if (!detailsToRender) {
            return (
                <div className="popup" style={{ paddingBottom: 0, minHeight: '420px' }}>
                    <div className="title mx-4 d-flex justify-content-center align-items-center">
                        <img className="pointer arrow-back" src={require('../../../assets/images/backArrow.png')} alt="back-arrow" onClick={() => closePopup()} />
                        <h1 className="text-center">Details</h1>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="spinner-border text-primary p-4 mx-auto mb-3 mt-5" role="status"></div>
                    </div>
                </div>
            )
        } else {
            const keys = Object.keys(detailsToRender)
            return (
                <div className="popup" style={{ paddingBottom: 0, minHeight: '420px' }}>
                    <div className="title mx-4 d-flex justify-content-center align-items-center">
                        <img className="pointer arrow-back" src={require('../../../assets/images/backArrow.png')} alt="back-arrow" onClick={() => closePopup()} />
                        <h1 className="text-center">Details</h1>
                    </div>
                    {keys.length === 0 ?
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="spinner-border text-primary p-4 mx-auto mb-3 mt-5" role="status"></div>
                        </div>
                        :
                        <div className="mt-4">
                            <ul className="nav nav-fill nav-tabs">
                                {keys && keys.length ? keys.map((key, index) => (
                                    <li key={index} className="nav-item">
                                        <a className={selectedTab === key ? "nav-link active" : "nav-link"} style={{ color: selectedTab === key ? '#009CD8' : '#424141', cursor: 'pointer' }} onClick={() => this.setState({ selectedTab: key })}>{key} </a>
                                    </li>
                                )) : null}
                            </ul>
                            {this.displayDetailsTable()}
                        </div>
                    }
                </div>
            )
        }
    }

    displayDetailsTable = () => {
        const { selectedTab } = this.state;
        const { projectDetails, details } = this.props;
        const detailsToRender = details ? details : projectDetails;
        if (selectedTab !== '') {
            let innerKeys;
            if (detailsToRender && detailsToRender[selectedTab]) {
                innerKeys = Object.keys(detailsToRender[selectedTab])
            }
            return (
                <table className="table">
                    <tbody className="lightGrey">
                        {innerKeys ? innerKeys.map((innerKey, index) => (
                            <tr key={index}>
                                <th scope="row" className="text-left p-3 pointer tableText">
                                    {innerKey}
                                </th>
                                <td className="text-left pr-4">
                                    {detailsToRender[selectedTab][innerKey]}
                                </td>
                            </tr>
                        )) : null}
                    </tbody>
                </table>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        projectDetails: state.projects.projectDetails,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getProjectDetails: (projectName) => dispatch(getProjectDetails(projectName)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)( withRouter(ShowDetails) );