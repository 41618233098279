import React, { useEffect, useState } from 'react';
import Modal from '../../core/Modal/Modal';
import TableNav from '../../core/TableNav/TableNav';
import Pagination from '../../core/Pagination/Pagination';
import { useHistory, useRouteMatch } from 'react-router-dom';


export default function ProjectUsers(props) {
	const match = useRouteMatch();
	const history = useHistory();
	const {path, url, params} = match;
	const {projectName} = params;

	const [isDeleting, setIsDeleting] = useState(false);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	
	const { users, getUsers, updateUsersToProject } = props;

	const filteredUsers = searchValue.length > 0 
		? users.filter(user => user.toLowerCase().includes(searchValue.toLowerCase())) 
		: users;


	useEffect(() => {
			getUsers(projectName);
	}, []);


	const addUsersToDeleteList = (user) => {
    if (selectedUsers.some(u => u === user)) {
      setSelectedUsers(selectedUsers.filter(u => u !== user));
    } else {
			setSelectedUsers([...selectedUsers, user]);
    }
  };

	const deleteUsers = async () => {
		const newUsers = users.filter(user => !selectedUsers.includes(user));
		await updateUsersToProject(newUsers, projectName);
		getUsers(projectName);
	};

	return (
			<div className="tableContainer">
					<TableNav
							admin
							primary={projectName}
							goToStart={() => { setIsDeleting(false); setSelectedUsers([]); history.push(`/admin`); }}
							cancel={() => { setIsDeleting(false); setSelectedUsers([]); }}
							setShowPopup={() => history.push(`/admin/projects/${projectName}/users/add`)}
							isClicked={isDeleting}
							setIsClicked={(action) => () => setIsDeleting(true)}
							searchHandler={(input) => setSearchValue(input)}
					/>
					<div className="col p-0">
							<div className="lightGrey">
									<div>
											<div className="tableRow">
													<div className="text-left p-3 pointer tableText font-weight-bold">
															Users - {projectName}
													</div>
											</div>
											<div className="innerTableContainer">
													{!users ?
															<div className="spinner-border text-primary p-3 mx-auto" role="status"></div>
															:
															users.length === 0 ?
																	<h4 className="m-0 p-5 text-center">There are no users in this project yet</h4>
																	:
																	<Pagination
																			list={filteredUsers}
																			isSelected={true}
																			deleteClicked={isDeleting}
																			selectedToDelete={selectedUsers}
																			addToDeleteList={addUsersToDeleteList}
																	/>
													}
											</div>
									</div>
							</div>
					</div>
					<Modal
							title={'Are you sure?'}
							users
							deleteUsers={deleteUsers}
							update={() => { setIsDeleting(false); setSelectedUsers([]); }}
					/>
			</div>
	);
}