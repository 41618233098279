import React from 'react';
import Button from '../Button/Button';
import { FaArrowLeft, FaAngleLeft } from 'react-icons/fa';


const TableNav = props => {
    const {
        primary,
        secondary,
        goBack,
        goToStart,
        cancel,
        isClicked,
        setIsClicked,
        setShowPopup,
        searchHandler,
        clickHandler,
        clickHandler2,
        admin,
        dsFlag,
        title,
        groupFlag,
        doAction,
    } = props;

    if (secondary || admin) {
        return (
            <div>
                <div className="row">
                    <div className="titleContainer">
                        <FaArrowLeft className=" pointer" color="white" size={24} onClick={admin ? goToStart : goBack}
                        />
                        <div className="title">{primary}</div>
                        {secondary ? <FaAngleLeft className="pointer" color="white" size={24} onClick={goBack} /> : null}
                        <div className="title">{secondary}</div>
                    </div>
                </div>
                <div className="options">
                    <div className="col-8 text-left">
                        {admin && dsFlag ?
                            null
                            :
                            <input
                                type='text'
                                id='input'
                                name='input'
                                className='field text-left'
                                placeholder='Search'
                                onChange={(event) => searchHandler(event.target.value)}>
                            </input>
                        }
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                        {admin && dsFlag ?
                            <div className="d-flex">
                                <div className="d-flex">
                                    <div className="mx-1 "><Button title={'RESET'} outlineBlue clickHandler={clickHandler2} /></div>
                                    <div className="mx-1 "><Button title={'UPDATE'} dataToggle="modal" dataTarget="#exampleModal" clickHandler={clickHandler} /></div>
                                </div>
                            </div>
                            : null}
                        {!(admin && dsFlag) ?
                            <div className="d-flex">
                                <div className="mx-1">
                                    {!isClicked ?
                                        <Button title={'ADD'} outlineBlue clickHandler={setShowPopup} />
                                        :
                                        <Button title={'CANCEL'} outlineBlue clickHandler={cancel} />
                                    }
                                </div>
                                <div className="mx-1">
                                    {!isClicked ? <>
                                            {dsFlag ? <Button title={'DEBUG'} clickHandler={setIsClicked('debug')} outlineBlue /> : null}
                                            <Button title={'DELETE'} clickHandler={setIsClicked('delete')} className="mx-1" />
                                        </>
                                        :
                                        < Button title={'CONFIRM'} dataToggle="modal" dataTarget="#exampleModal" clickHandler={doAction} />}
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <div className="row">
                    <div className="titleContainer">
                        <FaArrowLeft className="pointer" color="white" size={24} onClick={goToStart} />
                        <div className="title">{primary}</div>
                    </div>
                </div>
                <div>
                    {groupFlag ?
                        <div className="options">
                            <div scope="col" className="text-left ml-2 textMuted x">{title}</div>
                            <div className="d-flex col justify-content-end">
                                <div className="mx-1"><Button title={'ADD'} outlineBlue clickHandler={setShowPopup} /></div>
                            </div>
                        </div>
                        : dsFlag ?
                            <div className="options">
                                <div scope="col" className="text-left ml-2 textMuted x">{title}</div>
                                <div className="d-flex col justify-content-end">
                                    <div className="mx-1"><Button title={'DASHBOARDS'} clickHandler={clickHandler2} /></div>
                                    <div className="mx-1"><Button title={'DISTRIBUTIONS'} clickHandler={clickHandler} /></div>
                                    <div className="mx-1"><Button title={'DETAILS'} clickHandler={setShowPopup} /></div>
                                </div>
                            </div>
                        : null}
                </div>
            </div>
        )
    }
}

export default TableNav;