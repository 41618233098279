import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import axios from 'axios';
import './App.css';
import { API, AUTH_RESOURCE_ID } from './config';
import { authContext } from './services/auth/authProvider';
import Header from './components/core/Header/Header';
import Projects from './components/screens/Projects/Projects';
import Admin from './components/screens/Admin/Admin';


const routes = [
  {path: '/projects', title: 'Projects'},
];
const adminRoutes = [
  {path: '/admin', title: 'Admin'}
].concat(routes);


class App extends Component {

  state = {
    role: null,
  }

  render() {
    const { role } = this.state;
    const accountInfo = authContext._user?.profile;

    return (
        <Router>
          <div className="App">
            <Header
                tabs={role === 'admin' ? adminRoutes : routes}
                accountInfo={accountInfo}
                logout={this.logout}
            />

            {role ? (
              <Switch>
                <Route path='/projects'>
                  <Projects accountInfo={accountInfo} logout={this.logout} role={role} />
                </Route>
                <Route path='/admin'>
                  <Admin accountInfo={accountInfo} logout={this.logout} role={role} />
                </Route>
                <Route path='/'>
                  <Redirect to={role === 'admin' ? '/admin' : '/projects'} />
                </Route>
              </Switch>
            ) : (
              <div className="blueBackImage">
                <div className="spinner-border text-primary p-4 mx-auto"></div>
              </div>
            )}
          </div>
        </Router>
    )
  }

  componentDidMount() {
    this.setAxiosInterceptor();

    authContext.acquireToken(AUTH_RESOURCE_ID, async (error, token) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      const role = await axios.get(`${API}/role`);
      this.setState({ role: role.data });
    });
  }

  setAxiosInterceptor() {
    axios.interceptors.response.use(
      response => response, 
      error => {
        console.log(error.response)
        const status = error.response?.status;
        if (status === 449) {
          authContext.logOut();
        }
        return Promise.reject(error)
      }
    );
  }

  logout() {
    authContext.logOut();
  }
}

export default App;
