import React from 'react';

const Button = props => {
  const { dataToggle, dataTarget, dataDismiss, clickHandler, outlineBlue, outlineGrey, title, disabled, className } = props;

  const setStyle = () => {
    let classes = 'button'
    if (outlineBlue) {
      classes += ' outlineBlue'
    }
    if (outlineGrey) {
      classes += ' outlineGrey'
    }
    if (disabled) {
      classes += ' disabled'
    }
    return classes + ' ' + (className || '');
  }

  return (
    <button
      disabled={disabled ? true : false}
      data-toggle={dataToggle}
      data-target={dataTarget}
      data-dismiss={dataDismiss}
      className={setStyle()}
      onClick={clickHandler}>
      {title}
    </button>
  );
}

export default Button;               