import React, { Component } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

class Pagination extends Component {

    state = {
        oneStartAt: 0,
        oneEndAt: 10,
        twoEndAt: 20,
        threeEndAt: 30,
        // fourEndAt: 40,
    }

    render() {
        const { 
            oneEndAt, 
            twoEndAt, 
            threeEndAt, 
            // fourEndAt, 
            oneStartAt 
        } = this.state;
        
        const { 
            list, 
            isSelected, 
            deleteClicked, 
            selectedToDelete,
            addToDeleteList, 
            changeAdminDsList, 
            checkboxStatus, 
        } = this.props

        let col1 = [];
        let col2 = [];
        let col3 = [];
        // let col4 = [];

        if (isSelected && list && list.length > 0) {
            for (let i = oneStartAt; i < oneEndAt; i++) {
                col1.push(list[i])
            }
            for (let i = oneEndAt; i < twoEndAt; i++) {
                col2.push(list[i])
            }
            for (let i = twoEndAt; i < threeEndAt; i++) {
                col3.push(list[i])
            }
            // for (let i = threeEndAt; i < fourEndAt; i++) {
            //     col4.push(list[i])
            // }
            return (
                <div>
                    <div className="row p-3">
                        <div className="form-group form-check col-4">
                            {col1.map((el, i) => {
                                // console.log(el)
                                if (el) {
                                    return (
                                        <div key={i} className="py-2 px-3 justify-content-center">
                                            {deleteClicked && checkboxStatus == 'adminDs' ?
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input checkBox"
                                                    checked={selectedToDelete.some(t => t === el.name)}
                                                    onChange={() => addToDeleteList(el, i)}
                                                />
                                                :
                                                deleteClicked && checkboxStatus != 'adminDs' ?
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input checkBox"
                                                        checked={selectedToDelete.some(t => t === el)}
                                                        onChange={() => addToDeleteList(el, i)}
                                                    />
                                                    : null}
                                            {checkboxStatus != 'adminDs' ? <label className="tableText">{el}</label> : <label className="tableText">{el.name}</label>}
                                        </div>
                                    )
                                } else return null;
                            })}
                        </div>
                        <div className="form-group form-check col-4">
                            {col2.map((el, i) => {
                                if (el) {
                                    return (
                                        <div key={i} className="py-2 px-3 justify-content-center">
                                            {deleteClicked && checkboxStatus == 'adminDs' ?
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input checkBox"
                                                    checked={selectedToDelete.some(t => t === el.name)}
                                                    onChange={() => addToDeleteList(el, i)}
                                                />
                                                :
                                                deleteClicked && checkboxStatus != 'adminDs' ?
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input checkBox"
                                                        checked={selectedToDelete.some(t => t === el)}
                                                        onChange={() => addToDeleteList(el, i)}
                                                    />
                                                    : null}
                                            {checkboxStatus != 'adminDs' ? <label className="tableText">{el}</label> : <label className="tableText">{el.name}</label>}
                                        </div>
                                    )
                                } else return null;
                            })}
                        </div>
                        <div className="form-group form-check col-4">
                            {col3.map((el, i) => {
                                if (el) {
                                    return (
                                        <div key={i} className="py-2 px-3 justify-content-center">
                                            {deleteClicked && checkboxStatus == 'adminDs' ?
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input checkBox"
                                                    checked={selectedToDelete.some(t => t === el.name)}
                                                    onChange={() => addToDeleteList(el, i)}
                                                />
                                                :
                                                deleteClicked && checkboxStatus != 'adminDs' ?
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input checkBox"
                                                        checked={selectedToDelete.some(t => t === el)}
                                                        onChange={() => addToDeleteList(el, i)}
                                                    />
                                                    : null}

                                            {checkboxStatus != 'adminDs' ? <label className="tableText">{el}</label> : <label className="tableText">{el.name}</label>}
                                        </div>
                                    )
                                } else return null;
                            })}
                        </div>
                        {/* <div className="form-group form-check col-3">
                            {col4.map((el, i) => {
                                if (el) {
                                    return (
                                        <div key={i} className="py-2 px-3 justify-content-center">
                                            {deleteClicked && checkboxStatus == 'adminDs' ?
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input checkBox"
                                                    checked={el["enabled"]}
                                                    onChange={() => changeAdminDsList(el)}
                                                />
                                                :
                                                deleteClicked && checkboxStatus != 'adminDs' ?
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input checkBox"
                                                        checked={selectedToDelete.some(t => t === el)}
                                                        onChange={() => addToDeleteList(el, i)}
                                                    />
                                                    : null}

                                            {checkboxStatus != 'adminDs' ? <label className="tableText">{el}</label> : <label className="tableText">{el.name}</label>}
                                        </div>
                                    )
                                } else return null;
                            })}
                        </div> */}
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                        <button
                            className="noBorder mx-3"
                            disabled={oneStartAt === 0}
                            onClick={() => this.arrowClickHandler('prev')}
                        >
                            <FaAngleLeft className="pointer" size={18} />
                        </button>
                        <button
                            className="noBorder mx-3"
                            // disabled={fourEndAt > (list.length)}
                            disabled={threeEndAt > (list.length)}
                            onClick={() => this.arrowClickHandler('next')}
                        >
                            <FaAngleRight className="pointer" size={18} />
                        </button>
                        <div className="paginationText px-3">
                            {/* {`${oneStartAt + 1} - ${fourEndAt > list.length ? list.length : threeEndAt} of ${list.length}`} */}
                            {`${oneStartAt + 1} - ${threeEndAt > list.length ? list.length : threeEndAt} of ${list.length}`}
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }

    arrowClickHandler = (nav) => {
        this.setState(prevState => {
            return {
                oneStartAt: nav === 'next' ? prevState.oneStartAt + 10 : prevState.oneStartAt - 10,
                oneEndAt: nav === 'next' ? prevState.oneEndAt + 10 : prevState.oneEndAt - 10,
                twoEndAt: nav === 'next' ? prevState.twoEndAt + 10 : prevState.twoEndAt - 10,
                threeEndAt: nav === 'next' ? prevState.threeEndAt + 10 : prevState.threeEndAt - 10,
                // fourEndAt: nav === 'next' ? prevState.fourEndAt + 10 : prevState.fourEndAt - 10,
            }
        })
    }
}

export default Pagination;