import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import ReduxThunk from 'redux-thunk';
import AuthReducer from '../store/reducers/AuthReducer';
import ProjectsReducer from '../store/reducers/ProjectsReducer';
import AdminReducer from '../store/reducers/AdminReducer';

const rootReducer = combineReducers({
    auth: AuthReducer,
    projects: ProjectsReducer,
    admin: AdminReducer
})

const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(ReduxThunk),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
    )
);

export default store;