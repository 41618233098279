import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import Table from '../../core/Table/Table';
import {
  getProjectList,
  getDataSources,
  getGroupList,
  getGroupEmails,
  clearData,
} from '../../../store/actions/ProjectsActions';
import { MODE, API } from '../../../config';
import Project from './Project';
import EditProjectDlg from '../../popups/EditProject/EditProjectDlg';


class Projects extends Component {

  state = {
    selectedProjects: [],
    searchValue: '',
    fadeError: false,
    showError: true,
    project: null,
    isEditingProject: false,
    isLoading: false,
  }

  componentDidMount() {
    const user = this.getUser();
    this.props.getProjectList(user);
    
    setTimeout(() => { this.setState({fadeError: true}); }, 7000);
    setTimeout(() => { this.setState({showError: false}); }, 9500);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({fadeError: false, showError: true});
      window.history.replaceState({}, document.title);
    }
  }

  async updateProject(project) {
    this.setState({isEditingProject: false});
    if (!project || !project.name) { return; }

    try {
        this.setState({isLoading: true});
        await axios.post(`${API}/projects/${project.name}`, {project});
        await this.props.getProjectList(this.getUser());
    }
    catch (err) {
        console.log(`[Projects:updateProject] error:`, err);
    }
    finally {
        this.setState({isLoading: false});
    }
}

  render() {
    const { match, role, location } = this.props;
    const { path, url, params } = match;

    if (!role) {
      return (<div className="blueBackImage">
        <div className="spinner-border text-primary p-4 mx-auto"></div>
      </div>);
    }

    return (
      <div className="blueBackImage">

        {location?.state?.error && this.state.showError && (
          <div className={`error-msg alert alert-danger ${this.state.fadeError ? 'fadeout' : ''}`}><span className="error-x">❌</span> Error: {location.state.error}</div>
        )}

        <Switch>
          <Route path={path} exact>
            {this.displayProjectsTable()}
          </Route>

          <Route path={`${path}/:projectName`}>
            <Project {...this.props} />
          </Route>
        </Switch>
      </div>
    );
  }

  displayProjectsTable = () => {
    const { selectedProjects, searchValue, isEditingProject, project, isLoading } = this.state;
    const { projects, clearData, history } = this.props;
    
    if (!projects || isLoading) {
      return <div className="spinner-border text-primary p-4 mx-auto"></div>
    } else {

      let filteredProjects = projects;
      if (searchValue) {
          filteredProjects = projects.filter(({name}) => name?.toLowerCase().includes(searchValue.toLowerCase()));
      }

      const actions = [
        {name: 'Edit', active: 'single', showInTable: true, run: (project) => {
          this.setState({isEditingProject: true, project});
        }},
        {name: 'Details', active: 'single', showInTable: true, run: (project) => {
            clearData();
            history.push(`/projects/${project.name}/details`);
        }}, 
      ];

      const columns = [
          {title: 'Project Name', data: 'name', onClick: this.gotoProject, cellClass: 'font-weight-bold pointer tableText'},
          {title: 'Type', data: 'type'},
          {title: 'Manager', data: (proj) => `${proj.manager_email ?? ''}${proj.manager_name ? ` (${proj.manager_name})` : ''}`},
          {title: 'Contacts', data: 'contact_emails'},
          {title: 'Vendor', data: 'vendor_name'},
          {title: 'Description', data: 'description'},
          {title: 'Status', data: 'status'},
          {title: 'Comment', data: 'status_comment'},
      ];

      const filteredSelectedProjects = selectedProjects.filter(item => filteredProjects.includes(item));

      return (
        <>
          <Table 
              id="projects"
              title={<>Projects ({filteredProjects.length}) <span className="table-subtitle ml-2">({filteredSelectedProjects.length} selected)</span></>}
              items={filteredProjects}
              columns={columns}
              keyFn={({name}) => name}
              selectedItems={selectedProjects}
              setSelectedItems={(selectedProjects) => this.setState({selectedProjects})}
              actions={actions}
              pageSize={10}
              searchValue={searchValue}
              setSearchValue={(value) => this.setState({searchValue: value})}
              searchPlaceholder="Search for projects..."
          />

          <EditProjectDlg
              isOpen={isEditingProject}
              project={project}
              updateProject={(project) => this.updateProject(project)}
              onClose={() => this.setState({isEditingProject: false})}
          />
        </>
      )
    }
  }

  gotoProject = (project) => {
    const { clearData, history } = this.props;
    clearData();
    history.push(`/projects/${project.name}`);
  }

  getUser = () => {
    const { accountInfo } = this.props
    if (MODE === 'dev') {
      return localStorage.getItem('user');
    } else {
      return accountInfo.email;
    }
  }

}

const mapStateToProps = state => {
  return {
    projects: state.projects.projects,
    dataSources: state.projects.dataSources,
    groups: state.projects.groups,
    groupEmails: state.projects.groupEmails,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getProjectList: (user) => dispatch(getProjectList(user)),
    getDataSources: (projectName) => dispatch(getDataSources(projectName)),
    getGroupList: (projectName) => dispatch(getGroupList(projectName)),
    getGroupEmails: (projectName, groupName) => dispatch(getGroupEmails(projectName, groupName)),
    clearData: () => dispatch(clearData())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)( withRouter(Projects) );
