import React, { Component } from 'react';
import { Link, NavLink, withRouter } from "react-router-dom";
import { MODE } from '../../../config';

class Header extends Component {

    render() {
        const { tabs, accountInfo, logout } = this.props;
        const user = MODE === 'dev' ? localStorage.getItem('user') : accountInfo?.name;

        return (
            <header className="header">
                <div className="logoContainer">
                    <img className="logo" src={require('../../../assets/images/icl_logo.png')} alt="LOGO" />
                </div>

                {tabs.map((tab, idx) => (
                    <NavLink to={tab.path} key={idx} className="navItem" activeClassName="activeTab">
                        {tab.title}
                    </NavLink>
                ))}

                <div className="navText">
                    <div>{user},&nbsp;&nbsp;</div>
                    <span
                        className="d-flex justify-content-center align-items-center font-weight-bold mr-4"
                        style={{ color: '#009CD8', cursor: 'pointer' }}
                        onClick={MODE === 'dev' ? this.devLogout : logout}
                    >
                        Logout
                    </span>
                </div>
            </header>
        )
    }

    devLogout = () => {
        localStorage.clear();
        const location = {
            pathname: '/',
            state: {}
        }
        this.props.history.push(location)
    }
}

export default withRouter(Header);
