import axios from 'axios';
import { API, API_ADMIN } from '../../config';

export const SET_ALL_PROJECTS = 'SET_ALL_PROJECTS';
export const SET_ADMIN_DS = 'SET_ADMIN_DS';
export const SET_USERS = "SET_USERS";
export const UPDATE_ADMIN_DS = "UPDATE_ADMIN_DS";
export const SET_ADMIN_DS_SERVER = "SET_ADMIN_DS_SERVER";
export const ADD_USER_TO_PROJECT = "ADD_USER_TO_PROJECT";
export const UPDATE_USER_TO_PROJECT = "ADD_USER_TO_PROJECT";
export const SEARCH_USERS = "SEARCH_USERS";
export const CLEAR_ADMIN_DATA = "CLEAR_ADMIN_DATA";


export const getProjectList = () => {
    return async dispatch => {
        const response = await axios.get(`${API}/projects/list`)
        dispatch({ type: SET_ALL_PROJECTS, projects: response.data })
    }
}

export const addProjectHandler = (projectName) => {
    return async dispatch => {
        return await axios.post(`${API}/projects/create`, { project: projectName })
    }
}

export const deleteProjectHandler = (projectName) => {
    return async dispatch => {
        return await axios.post(`${API}/projects/remove`, { project: projectName })
    }
}

export const getUsers = (projectName) => {
    return async dispatch => {
        const response = await axios.get(`${API_ADMIN}/users?project=${projectName}`)
        dispatch({ type: SET_USERS, users: response["data"].data })
    }
}

export const searchUsers = (input) => {
    return { type: SEARCH_USERS, input }
}

export const updateAdminDs = (dsList) => {
    return { type: UPDATE_ADMIN_DS, updatedAdminDs: dsList }
}

export const getAdminDataSources = (projectName) => {
    return async dispatch => {
        const response = await axios.get(`${API_ADMIN}/DataSources?project=${projectName}`)
        if (response["data"])
            dispatch({ type: SET_ADMIN_DS, adminDataSources: response["data"].data })
    }
}


export const setAdminDataSourcesServer = (data, projectName) => {
    return async dispatch => {
        const response = await axios.post(`${API_ADMIN}/DataSources?project=${projectName}`, data)
        if (response["data"])
            dispatch({ type: SET_ADMIN_DS_SERVER, adminDataSourcesResponse: response["data"].data })
    }
}
export const addUsersToProject = (data, projectName) => {
    return async dispatch => {
        let userList = [];
        data.forEach(user => {
            userList.push({
                project: projectName,
                email: user
            })
        })  
        const response = await axios.post(`${API_ADMIN}/users?project=${projectName}`, userList)
        if (response["data"]) {
            dispatch({ type: ADD_USER_TO_PROJECT, addUsersToProjectResponse: response["data"].data })
        }
        return response
    }
}
export const updateUsersToProject = (data, projectName) => {
    return async dispatch => {
        let userList = [];
        data.forEach(user => {
            userList.push({
                project: projectName,
                email: user
            })
        })        
        const response = await axios.put(`${API_ADMIN}/users?project=${projectName}`, userList)
        if (response["data"])
            dispatch({ type: UPDATE_USER_TO_PROJECT, updateUsersToProjectResponse: response["data"].data })
    }
}

export const clearAdminData = alsoProjects => {
    return { type: CLEAR_ADMIN_DATA, alsoProjects }
}
