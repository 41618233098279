export const APIDomain = 'https://dlui.server.appsb.icldig.icl-group.com';
export const clientBaseUrl = 'https://dlui.appsb.icldig.icl-group.com/';

export const API = `${APIDomain}/api/rest/data`;
export const API_ADMIN = `${APIDomain}/api/rest/data/admin`;

export const AUTH_RESOURCE_ID = '29de178c-bc4b-4e42-88e8-48604cb2ebe1';

//export const MODE = process.env.REACT_APP_MODE;
export const MODE = 'prod';
