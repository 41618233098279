import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import Store from './store/Store';
import {authContext} from './services/auth/authProvider'
import { runWithAdal } from 'react-adal';


runWithAdal(authContext, render);

function render() {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={Store}>
        <App />
      </Provider>,
    </React.StrictMode>,
    document.getElementById('root')
  )
}

serviceWorker.unregister();