import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import Table from '../../core/Table/Table';
import Modal from '../../core/Modal/Modal';


export default function Groups(props) {
	const match = useRouteMatch();
	const history = useHistory();
	const {path, url, params} = match;
	const {projectName} = params;

  const { groups, getGroupList, accountInfo } = props;

  const [groupName, setGroupName] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [selectedGroups, setSelectedGroups] = useState([]);

  useEffect(() => {
		run(); 

		async function run() {
			try {
				await getGroupList(projectName);
			}
			catch (err) {
				console.log('[Groups] getGroupList error:', err);
				history.replace('/projects', {error: `User ${accountInfo.email} is not authorized to access project ${projectName}`});
			}
		}    
  }, [])


  if (!groups) {
    return <div className="spinner-border text-primary p-4 mx-auto" role="status"></div>
  } else {
    let filteredGroups = groups.map(fullGroupName => fullGroupName.slice("DIG-DATALAKE-".length + projectName.length + 1));
    if (searchValue) {
        filteredGroups = filteredGroups.filter(group => group?.toLowerCase().includes(searchValue.toLowerCase()));
    }

    const actions = [
      {name: 'Add Distribution', active: 'always', position: 'left', run: () => history.push(`${url}/add`)},
      {name: 'Delete', active: 'single', showInTable: true, run: (groupName) => {
        setGroupName(`DIG-DATALAKE-${projectName}-${groupName}`);
        /* eslint-env jquery */
        $('#exampleModal').modal();
      }}, 
    ];

    const columns = [
       {title: 'Name', data: name => name, onClick: (group) => history.push(`${url}/${group}`), cellClass: 'font-weight-bold pointer tableText'}
    ];

    const filteredSelectedGroups = selectedGroups.filter(item => filteredGroups.includes(item));

    return (
      <>
          <div className="d-flex">
              <FaArrowLeft className="pointer" color="white" size={24} onClick={() => history.push(`/projects/${projectName}`)} />
              <div className="title">{projectName}</div>
          </div>

        <Table 
            title={<>Distributions ({filteredGroups.length}) <span className="table-subtitle ml-2">({filteredSelectedGroups.length} selected)</span></>}
            items={filteredGroups}
            columns={columns}
            keyFn={(groupName) => groupName}
            selectedItems={selectedGroups}
            setSelectedItems={(selectedGroups) => setSelectedGroups(selectedGroups)}
            actions={actions}
            pageSize={20}
            searchValue={searchValue}
            setSearchValue={(value) => setSearchValue(value)}
            searchPlaceholder="Search for distributions..."
        />

        <Modal
          deleteGroupFlag
          title={'Are you sure?'}
          projectName={projectName}
          groupName={groupName}
          update={() => getGroupList(projectName)}
        />
      </>
    )
  }
}