import React, { FC } from 'react';

export type DialogButton = {
    title: string;
    primary?: boolean;
    onClick: () => void;
}

export type DialogProps = {
    isOpen: boolean;
    title: string;
    size?: 'sm' | 'lg' | 'xl';
    maxHeight?: number;
    buttons?: DialogButton[];
    onCancel: () => void;
};


const Dialog: FC<DialogProps> = (props) => {
    const {isOpen, title, size, maxHeight, buttons, onCancel, children} = props;

    return (
        <>
            <div className={`modal fade ${isOpen ? 'show d-block' : ''}`} onClick={onCancel}>
                <div className={`modal-dialog modal-dialog-centered modal-dialog-scrollable ${size ? `modal-${size}` : ''}`} onClick={(e) => e.stopPropagation()}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{title}</h5>
                            <button type="button" className="close" onClick={onCancel}><span>&times;</span></button>
                        </div>
                        <div className="modal-body text-left" style={{maxHeight}}>
                            {children}
                        </div>
                        {buttons && (
                            <div className="modal-footer">
                                {buttons.map(({title, primary, onClick}) => (
                                    <button 
                                        key={title} type="button" 
                                        className={`btn btn-${primary ? 'brand' : 'secondary'}`}
                                        onClick={(e) => onClick()}
                                    >
                                        {title}
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {isOpen && <div className={`modal-backdrop fade show`} onClick={onCancel} />}
        </>
    );
};

export default Dialog;