import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Dialog, { DialogButton } from '../../core/Dialog/Dialog';
import { API } from '../../../config';

type RetroSelectProps = {
    isOpen: boolean;
    projectName: string;
    email: string;
    onClose: () => void;
};


export default function RetroSelectDlg(props: RetroSelectProps) {
    const {isOpen, projectName, email, onClose} = props;

    const [isLoading, setIsLoading] = useState( false );
    const [isFinished, setIsFinished] = useState( false );
    const [error, setError] = useState<string>();
    const [startDate, setStartDate] = useState<string>( defaultDateInput() );

    useEffect(() => {
        if (isOpen) {
            setIsLoading(false);
            setIsFinished(false);
            setError(undefined);
        }
    }, [isOpen]);


    const submit = async () => {
        if (isLoading) { return; }

        const data = {
            project: projectName,
            email,
            start_date: dateInputToApiStr(startDate)
        };

        setIsFinished(false);
        setError(undefined);
        setIsLoading(true);

        try {
            const result = await axios.post(`${API}/tags/retro`, data);
            console.log(`[retro-select] submit result:`, result.data);
        }
        catch (err) {
            console.log(`[retro-select] submit error:`, err);
            setError((err as Error).message ?? (err as Error).toString())
        }
        finally {
            setIsLoading(false);
            setIsFinished(true);
        }
    };


    let buttons: DialogButton[] | undefined;

    if (!isLoading && !isFinished) {
        buttons = [
            {title: "Cancel", onClick: onClose}, 
            {title: "Submit", primary: true, onClick: submit}
        ];
    }
    else if (isFinished) {
        buttons = [{title: 'Close', primary: true, onClick: onClose}];
    }


    return (
        <Dialog
            isOpen={isOpen}
            title="Retro Select"
            buttons={buttons}
            onCancel={onClose}
        >
            {isLoading ? (
                <div className="text-center"><div className="spinner-border text-primary p-3 mx-auto" /></div>
            ) : isFinished ? (
                <div>{error ? `❌ There was a problem with the request: ${error}` : `✔ The request was sent successfully`}</div>
            ) : (
                <>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Project:</label>
                        <div className="col-sm-9 col-form-label">{projectName}</div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Email:</label>
                        <div className="col-sm-9 col-form-label">{email}</div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label" htmlFor="retroStartDate">Start Date:</label>
                        <div className="col-sm-9">
                            <input type="date" className="form-control" id="retroStartDate" 
                                value={startDate} onChange={(e) => setStartDate(e.target.value)} 
                            />
                        </div>
                    </div>
                </>
            )}
        </Dialog>
    );
}



function dateToInput(date: Date): string {
    return `${date.getFullYear()}-${String(date.getMonth()+1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
}

function dateInputToApiStr(str: string): string {
    const [YYYY,MM,DD] = str.split('-');
    return `${DD}-${MM}-${YYYY}`;
}

function defaultDateInput(): string {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    return dateToInput(date);
}