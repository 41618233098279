import React, { useEffect, useState } from 'react';
import Modal from '../../core/Modal/Modal';
import TableNav from '../../core/TableNav/TableNav';
import Pagination from '../../core/Pagination/Pagination';
import { useHistory, useRouteMatch } from 'react-router-dom';


export default function Group(props) {
	const match = useRouteMatch();
	const history = useHistory();
	const {path, url, params} = match;
	const {projectName, groupName} = params;

	const [emails, setEmails] = useState([]);
	const [selectedEmails, setSelectedEmails] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [searchValue, setSearchValue] = useState('');

	const { getGroupEmails, accountInfo } = props;

	const filteredEmails = searchValue.length > 0 
		? emails.filter(email => email.toLowerCase().includes(searchValue.toLowerCase())) 
		: emails;


	useEffect(() => {
		const loadEmails = async() => {
			setIsLoading(true);
			const fullGroupName = `DIG-DATALAKE-${projectName}-${groupName}`;
			try {
				const emails = await getGroupEmails(projectName, fullGroupName);
				setEmails(emails);
				setIsLoading(false);
				setSelectedEmails([]);
			}
			catch (err) {
				console.log('[Group:loadEmails] getGroupEmails error:', err);
				history.replace('/projects', {error: `User ${accountInfo.email} is not authorized to access project ${projectName}`});
			}
		};

		loadEmails();
	}, [projectName, groupName, accountInfo]);




  const addEmailsToDeleteList = (email) => {
    if (selectedEmails.some(e => e === email)) {
      setSelectedEmails(selectedEmails.filter(e => e !== email));
    } else {
			setSelectedEmails([...selectedEmails, email]);
    }
  };



	if (isLoading) {
		return (
			<div className="spinner-border text-primary p-4 mx-auto" role="status"></div>
		);
	}

	return (
		<div className="tableContainer">
			<TableNav
				groupFlag
				primary={projectName}
				secondary={groupName}
				goBack={() => history.push(`/projects/${projectName}/distributions`) }
				cancel={() => { setSelectedEmails([]); setIsDeleting(false); }}
				isClicked={isDeleting}
				setShowPopup={() => history.push(`/projects/${projectName}/distributions/${groupName}/add`)}
				setIsClicked={(action) => () => setIsDeleting(true)}
				searchHandler={(value) => setSearchValue(value)}
			/>
			<div className="col p-0">
				<div className="lightGrey">
					<div>
						<div className="tableRow">
							<div className="text-left p-3 pointer tableText">
								{groupName}
							</div>
						</div>
						<div className="innerTableContainer">
							<div className="text-left mb-3 tableText">List of email addresses connected to groups</div>
							<Pagination
								list={filteredEmails}
								isSelected={true}
								deleteClicked={isDeleting}
								selectedToDelete={selectedEmails}
								addToDeleteList={addEmailsToDeleteList}
							/>
						</div>
					</div>
				</div>
			</div>
			
			<Modal
				title={'Are you sure?'}
				projectName={projectName}
				groupName={`DIG-DATALAKE-${projectName}-${groupName}`}
				emails={selectedEmails}
				update={() => { setSelectedEmails([]); setIsDeleting(false); }}
			/>
		</div>
	)	
}